import styled, { css } from "styled-components";

import { pxToRem } from "@anwb/style-utilities";
import { DefaultThemeProps } from "@anwb/theme";

const headerLayoutWrapperStyles = ({ theme }: DefaultThemeProps) => css`
  display: grid;

  @media (min-width: ${pxToRem(theme.viewportBreakpoint.lg)}) {
    padding-bottom: 3rem;
  }
`;

export const HeaderLayoutWrapper = styled.div`
  ${headerLayoutWrapperStyles};
`;
