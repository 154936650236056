import React from "react";

import {
  OnBoardingContent,
  OnBoardingContentImage,
  OnBoardingContentSlide,
  OnBoardingContentTitle,
  OnBoardingGridItem,
  OnBoardingList,
} from "@components/Onboarding/styles/onboardingSlide.styled";
import { OnBoardingContentType } from "@domain/types";

import Grid from "@anwb/grid";
import GridItem from "@anwb/grid/dist/GridItem";
import Icon from "@anwb/icon";

const ContentSlide = ({ content }: { content: OnBoardingContentType }) => {
  return (
    <OnBoardingContentSlide>
      <Grid columns={{ sm: 1, md: 2, lg: 2 }}>
        <OnBoardingGridItem>
          <OnBoardingContentTitle isLastSlide={content.isLastSlide}>
            {content.title}
          </OnBoardingContentTitle>
          <OnBoardingContent dangerouslySetInnerHTML={{ __html: content.text }} />
          {content.list && (
            <OnBoardingList>
              {content.list.map((item, key) => (
                <li key={key}>
                  <Icon variant={"success"} />
                  <div>{item}</div>
                </li>
              ))}
            </OnBoardingList>
          )}
        </OnBoardingGridItem>
        <GridItem>
          <OnBoardingContentImage src={content.image} />
        </GridItem>
      </Grid>
    </OnBoardingContentSlide>
  );
};

export default ContentSlide;
