import { Dispatch } from "react";
import { useOutletContext } from "react-router-dom";

export type Progress = [number, number] | undefined;
type PlayerContextType = {
  progress: number[];
  setProgress: Dispatch<Progress>;
};

export const usePlayerState = () => {
  return useOutletContext<PlayerContextType>();
};
