import useCourse from "@hooks/useCourse";

export default () => {
  const { course, ...courseData } = useCourse();

  return {
    ...courseData,
    chapters: course?.children,
  };
};
