import { useEffect } from "react";

import { useClient } from "@components/ClientProvider";
import { useUser } from "@hooks/useUser";
import { getH5PQueryKey } from "@hooks/utils";
import { useQueryClient } from "@tanstack/react-query";

import NewRelicHelper from "@topgun/shared/src/helper/NewRelicHelper";

export default (nextContentId: string | undefined) => {
  const queryClient = useQueryClient();
  const { h5pClient } = useClient();
  const user = useUser();

  useEffect(() => {
    if (!user || !nextContentId) {
      return;
    }

    queryClient
      .prefetchQuery({
        queryKey: getH5PQueryKey(nextContentId, user.accessToken),
        queryFn: async ({ signal }) => {
          return h5pClient.getPlay(nextContentId, user.accessToken, signal);
        },
      })
      .catch((error) => {
        NewRelicHelper.logError(error);
      });
  }, [nextContentId, queryClient, h5pClient, user?.accessToken]);
};
