import { DynamicActivity, IConfiguration, IResult } from "@domain/types";
import axios, { AxiosError, AxiosHeaders, AxiosInstance, AxiosResponse } from "axios";
import camelcaseKeys from "camelcase-keys";

import { IContent } from "@topgun/shared/src/types/frontend";

interface ISearchParams {
  depth?: number;
}

type DataResponse = {
  message: string;
  error: string;
  reason: string;
  type: string;
};

export class DataClient {
  private readonly axios: AxiosInstance;

  constructor(baseURL: string) {
    this.axios = axios.create({
      timeout: 50_000,
      baseURL,
      headers: {
        "App-Version": `${APPLICATION_KEY}:${APPLICATION_VERSION}`,
      },
    });

    this.axios.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error: AxiosError) => {
        if (
          error?.response?.status === 403 &&
          (error?.response?.data as DataResponse).error === "no_license"
        ) {
          window.location.href = `/licence-error`;
        }

        return Promise.reject(error);
      },
    );
  }

  public async getContent(
    userId: string,
    token: string,
    signal: AbortSignal | undefined,
  ): Promise<IContent[]> {
    const data: IContent[] = await this.fetchData(
      `/users/${userId}/content`,
      {},
      {
        signal,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    if (data?.length === 0) {
      throw new Error("Missing content data");
    }
    return data;
  }

  public async getUserProgress(
    userId: string,
    signal: AbortSignal | undefined,
    token: string,
  ): Promise<string[]> {
    return this.fetchData(
      `user/${userId}/progress`,
      {},
      {
        signal,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  }

  public async getDynamicActivityConfigurations(
    signal: AbortSignal | undefined,
    token: string,
    queryParameters: { [key: string]: string | string[] | undefined } = {},
  ): Promise<IConfiguration[]> {
    const data: IConfiguration[] = await this.fetchData(`configurations`, queryParameters, {
      signal,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return camelcaseKeys(data);
  }

  public async getConfiguration(
    configurationId: string,
    signal: AbortSignal | undefined,
    token: string,
  ): Promise<IConfiguration> {
    if (!configurationId) {
      throw new Error("No configuration id found");
    }
    const data: IConfiguration = await this.fetchData(
      `configurations/${configurationId}`,
      {},
      {
        signal,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    if (!data) {
      throw new Error("Missing configuration data");
    }
    return camelcaseKeys(data);
  }

  public async createDynamicActivity(
    configurationId: string,
    token: string,
  ): Promise<DynamicActivity> {
    const data: DynamicActivity = await this.fetchData(
      `configurations/${configurationId}/dynamic-activities`,
      {},
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    if (!data) {
      throw new Error("Missing dynamic activity data");
    }
    return camelcaseKeys(data);
  }

  public async getDynamicActivity(
    dynamicActivityId: string,
    signal: AbortSignal | undefined,
    token: string,
  ): Promise<IConfiguration> {
    if (!dynamicActivityId) {
      throw new Error("No dynamic activity id found");
    }
    const data: IConfiguration = await this.fetchData(
      `dynamic-activities/${dynamicActivityId}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        signal,
      },
    );
    if (!data) {
      throw new Error("Missing configuration data");
    }
    return camelcaseKeys(data);
  }

  public async getDynamicActivityResult(
    dynamicActivityId: string,
    signal: AbortSignal,
    token: string,
  ): Promise<IResult> {
    if (!dynamicActivityId) {
      throw new Error("No dynamic activity id found");
    }
    const data: IResult = await this.fetchData(
      `dynamic-activities/${dynamicActivityId}/results`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        signal,
      },
    );
    if (!data) {
      throw new Error("Missing result data");
    }

    return camelcaseKeys(data, { deep: true });
  }

  private async fetchData<T>(path: string, params: ISearchParams, init?: RequestInit): Promise<T> {
    const resp = await this.fetch(path, params, init);
    return resp.data as T;
  }

  private async fetch(
    path: string,
    params: ISearchParams,
    init?: RequestInit,
  ): Promise<AxiosResponse> {
    return this.axios.request({
      url: path,
      method: init?.method,
      params,
      headers: init?.headers as AxiosHeaders,
      data: init?.body,
      signal: init?.signal ?? undefined,
    });
  }
}
