import { Dispatch, createContext } from "react";

import { BreadCrumb, ConfirmPageLeaveOptions, NavigateBackProps } from "@domain/types";

type HeaderState = {
  showHeaderLogo: boolean;
  showHeaderMenu: boolean;
  showOnboarding: boolean;
  navigateBack: NavigateBackProps | undefined;
  breadCrumbs: BreadCrumb[] | undefined;

  confirmPageLeave: boolean;
  confirmPageLeaveOptions: ConfirmPageLeaveOptions | undefined;
};

type HeaderStateAction =
  | { type: "header_logo"; value: HeaderState["showHeaderLogo"] }
  | { type: "header_menu"; value: HeaderState["showHeaderMenu"] }
  | { type: "onboarding"; value: HeaderState["showOnboarding"] }
  | { type: "navigate_back"; value: HeaderState["navigateBack"] }
  | { type: "breadcrumb"; value: HeaderState["breadCrumbs"] }
  | {
      type: "confirm_page_leave";
      value: HeaderState["confirmPageLeave"];
      options?: HeaderState["confirmPageLeaveOptions"];
    }
  | {
      type: "confirm_page_leave_options";
      value: HeaderState["confirmPageLeaveOptions"];
    };

export function tasksReducer(state: HeaderState, action: HeaderStateAction): HeaderState {
  switch (action.type) {
    case "header_logo": {
      return {
        ...state,
        showHeaderLogo: action.value,
      };
    }
    case "header_menu": {
      return {
        ...state,
        showHeaderMenu: action.value,
      };
    }
    case "onboarding": {
      return {
        ...state,
        showOnboarding: action.value,
      };
    }
    case "navigate_back": {
      return {
        ...state,
        navigateBack: action.value,
      };
    }
    case "breadcrumb": {
      return {
        ...state,
        breadCrumbs: action.value,
      };
    }
    case "confirm_page_leave": {
      return {
        ...state,
        confirmPageLeave: action.value,
        ...(action.options && {
          confirmPageLeaveOptions: {
            ...action.options,
          },
        }),
      };
    }
    case "confirm_page_leave_options": {
      return {
        ...state,
        confirmPageLeaveOptions: action.value,
      };
    }
    default: {
      return state;
    }
  }
}

export const HeaderContext = createContext<HeaderState | undefined>(undefined);
export const HeaderUpdaterContext = createContext<Dispatch<HeaderStateAction> | undefined>(
  undefined,
);
