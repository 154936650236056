import { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";

import ChapterNumberTag from "@components/ChapterNumberTag";
import { ContentItem } from "@domain/types";
import { animated, config, useSpring } from "@react-spring/web";
import { Node } from "@utils/tree/Node";

import {
  ChapterImageHeader,
  ChapterImageTitle,
  NextChapterCardWrapper,
  NextChapterImage,
} from "./styles";

type NextChapterCardProps = {
  nextChapter: Node<ContentItem>;
  showChapterTitle?: boolean;
  animate?: boolean;
  animateDelay?: number;
};

const AnimatedNextChapterCardWrapper = animated(NextChapterCardWrapper);

const NextChapterCard: FunctionComponent<NextChapterCardProps> = ({
  nextChapter,
  showChapterTitle = true,
  animate = false,
  animateDelay = undefined,
}) => {
  const navigate = useNavigate();
  const styles = useSpring({
    from: {
      opacity: 0,
      transform: "translateY(50px)",
    },
    to: {
      opacity: 1,
      transform: "translateY(0px)",
    },
    immediate: !animate,
    delay: animateDelay,
    config: config.wobbly,
  });

  const handleGoToChapter = (chapterId: string) => {
    navigate(`/chapters/${chapterId}`);
  };

  const { id, title, sequenceLabel, image } = nextChapter.value;

  return (
    <AnimatedNextChapterCardWrapper style={styles} data-testid="next-chapter-card">
      <NextChapterImage image={image} onClick={() => handleGoToChapter(id)}>
        <ChapterImageHeader>
          {sequenceLabel && <ChapterNumberTag chapterNumber={sequenceLabel} />}
          {showChapterTitle && <ChapterImageTitle tagName="h4">{title}</ChapterImageTitle>}
        </ChapterImageHeader>
      </NextChapterImage>
    </AnimatedNextChapterCardWrapper>
  );
};

export default NextChapterCard;
