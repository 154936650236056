import { animated } from "@react-spring/web";
import styled from "styled-components";

interface DefaultProps {
  key: string;
  style?: object;
}

export const RouteWrapper = styled(animated.div)<DefaultProps>`
  position: absolute;
  height: 100%;
  width: 100%;
`;
