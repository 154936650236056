import { useMemo } from "react";

import { PageResponse, Status } from "@domain/types";
import useCourse from "@hooks/useCourse";

import { ContentType } from "@topgun/shared/src/types/frontend";

export default () => {
  const { course, ...courseData } = useCourse();

  const data = useMemo((): PageResponse | undefined => {
    if (!course) {
      return undefined;
    }

    let firstPage = undefined;
    let firstOpenPageWithinDoing = undefined;
    for (const node of course.depthFirstPostOrderTraversal()) {
      const { type, status } = node.value;
      if (type !== ContentType.PAGE) {
        continue;
      }
      firstPage = firstPage || node;

      if (status === Status.OPEN) {
        firstOpenPageWithinDoing = node;
        break;
      }
    }

    const resultPage = firstOpenPageWithinDoing || firstPage;
    if (!resultPage) {
      return undefined;
    }

    if (!resultPage.hasParent()) {
      throw new Error("Page is not part of an activity");
    }

    const activity = resultPage.parent;
    if (!activity.hasParent()) {
      throw new Error("Activity is not part of an section");
    }

    const section = activity.parent;
    if (!section.hasParent()) {
      throw new Error("Section is not part of an chapter");
    }

    const chapter = section.parent;
    if (!chapter.hasParent()) {
      throw new Error("Chapter is not part of an course");
    }

    if (!course || course !== chapter.parent) {
      throw new Error("Course is not defined");
    }

    return {
      page: resultPage,
      activity,
      section,
      chapter,
      course,
    };
  }, [course]);

  return {
    ...courseData,
    data,
  };
};
