import { ActivityOverlayWrapper, ActivityStructureWrapper } from "@components/Chapter/styles";
import { ResultAndButtonWrapper } from "@components/Trainer/styles/Trainer.styled";
import styled, { css } from "styled-components";

import { pxToRem } from "@anwb/style-utilities";
import { DefaultThemeProps } from "@anwb/theme";

export const TrainerStructureWrapper = styled(ActivityStructureWrapper)`
  margin: 0;
  padding: 1rem 0.5rem;
  border: none;
  border-radius: 1rem;
  cursor: pointer;

  &.no-button {
    cursor: auto;

    &:hover {
      background: rgba(255, 255, 255, 0.1);
    }
  }
`;

const TrainerResultAndButtonWrapperStyles = ({ theme }: DefaultThemeProps) => css`
  gap: 1.5rem;
  background-color: transparent;

  &&& {
    padding: 0;
  }

  @media (min-width: ${pxToRem(theme.viewportBreakpoint.sm)}) {
    &&& {
      padding: 1.5rem;
    }

    background-color: rgba(255, 255, 255, 0.1);
  }
`;

export const TrainerResultAndButtonWrapper = styled(ResultAndButtonWrapper)`
  ${TrainerResultAndButtonWrapperStyles}
`;

const TrainerIconWrapperStyles = ({ theme }: DefaultThemeProps) => css`
  width: 2rem;
  display: flex;

  @media (min-width: ${pxToRem(theme.viewportBreakpoint.sm)}) {
    width: 3rem;
  }
`;

export const TrainerIconWrapper = styled.div`
  ${TrainerIconWrapperStyles}
`;

const TrainerStatusWrapperStyles = ({ theme }: DefaultThemeProps) => css`
  margin-left: auto;
  display: none;

  @media (min-width: ${pxToRem(theme.viewportBreakpoint.sm)}) {
    display: block;
  }
`;

export const TrainerStatusWrapper = styled.div`
  ${TrainerStatusWrapperStyles}
`;
export const TrainerOverlayWrapper = styled(ActivityOverlayWrapper)`
  cursor: inherit;
`;
