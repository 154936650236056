import { useOutletContext } from "react-router-dom";

import { IConfiguration } from "@domain/types";

type TrainerContextType = {
  dynamicActivity: IConfiguration;
};

export const useTrainerState = () => {
  return useOutletContext<TrainerContextType>();
};
