import { css } from "styled-components";

export const scrollbarStyling = () => css`
  &::-webkit-scrollbar {
    background-color: transparent;
    width: 14px;
    border: solid 3px transparent;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
    border: solid 3px transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-width: 4px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 20px;
    background-color: #606060;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }
`;
