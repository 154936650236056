import {
  HeadingCompressedBoldLStyles,
  HeadingCompressedBoldMStyles,
} from "@components/typography/shared/Typography.styled";
import styled from "styled-components";

import { pxToRem } from "@anwb/style-utilities";
import { DefaultThemeProps } from "@anwb/theme";
import Typography, { TypographyBaseProps } from "@anwb/typography";

export const HeadingCompressedBoldL = styled(Typography)<TypographyBaseProps>`
  ${HeadingCompressedBoldLStyles};

  @media (max-width: ${(props: DefaultThemeProps) => pxToRem(props.theme.viewportBreakpoint.md)}) {
    ${HeadingCompressedBoldMStyles};
  }
`;

export default HeadingCompressedBoldL;
