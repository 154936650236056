import { useEffect, useState } from "react";

import { useConfigContext } from "@components/ConfigLoader";
import axios, { AxiosError } from "axios";
import { jwtDecode } from "jwt-decode";
import { User, useAuth } from "oidc-react";

import { AuthenticationResult } from "@topgun/user-registration/src/schemas/AuthenticationResult";

async function getUser(licenseId: string, joinedVia: string, registerUrl: string) {
  const response = await axios.post<AuthenticationResult>(registerUrl, {
    licenseId,
    joinedVia,
  });
  const authenticationResult = response.data;
  const expiresAt = Math.round((Date.now() + Number(authenticationResult.ExpiresIn) * 1000) / 1000);
  return new User({
    id_token: authenticationResult.IdToken,
    access_token: authenticationResult.AccessToken,
    refresh_token: authenticationResult.RefreshToken,
    expires_at: expiresAt,
    token_type: authenticationResult.TokenType,
    profile: jwtDecode(authenticationResult.IdToken),
  });
}

export enum RegisterStatus {
  Loading,
  LoggedIn,
  Forbidden,
}

export default function useTokensAndLogin(licenseId: string, joinedVia: string) {
  const [registerStatus, setRegisterStatus] = useState(RegisterStatus.Loading);
  const { registerUrl } = useConfigContext();
  const { userManager } = useAuth();

  useEffect(() => {
    getUser(licenseId, joinedVia, registerUrl)
      .then((user: User) => {
        userManager
          .storeUser(user)
          .then(() => {
            setRegisterStatus(RegisterStatus.LoggedIn);
          })
          .catch((reason) => {
            throw reason;
          });
      })
      .catch((reason) => {
        if (reason instanceof AxiosError && reason.code === "403") {
          setRegisterStatus(RegisterStatus.Forbidden);
          return;
        }
        throw reason;
      });
  }, [setRegisterStatus, userManager]);
  return registerStatus;
}
