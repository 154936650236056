import styled, { css } from "styled-components";

import { NotificationContainer, NotificationContent } from "@anwb/notification";
import { pxToRem } from "@anwb/style-utilities";
import { Theme } from "@anwb/theme";

import { StyledNotificationProps } from "../Notification";

type NotificationStylingProps = {
  $alignNotification: StyledNotificationProps["$alignNotification"];
  theme: Theme;
};

const notificationStyling = ({ $alignNotification, theme }: NotificationStylingProps) => {
  const alignStyles = $alignNotification
    ? css`
        width: 100%;
        max-width: 80rem;
        margin-left: auto;
        margin-right: auto;
        padding: 7rem 1rem 1rem;

        @media (min-width: ${pxToRem(theme.viewportBreakpoint.sm)}) {
          padding: 7rem 2rem 2rem;
        }

        ${NotificationContainer} {
          width: auto;
        }
      `
    : "";

  return css`
    ${NotificationContent} {
      color: #000307;
    }
    ${alignStyles}
  `;
};

export const NotificationWrapper = styled.div`
  ${notificationStyling}
`;
