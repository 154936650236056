import styled, { css } from "styled-components";

import Icon from "@anwb/icon";
import LinkContainer from "@anwb/link";
import ApplicationSizeProvider from "@anwb/providers-application";
import { DefaultThemeProps } from "@anwb/theme";

export const ApplicationWrapper = styled(ApplicationSizeProvider)`
  height: 100%;
`;

export const Link = styled(LinkContainer).attrs(() => ({
  colorInverted: true,
}))``;

export const StatusDoneIcon = styled(Icon).attrs(() => ({
  variant: "success",
}))`
  color: #27964c;
`;

export const StatusDoingIcon = styled(Icon).attrs(() => ({
  variant: "pinpoint",
}))`
  color: #ffcd00;
`;

const StatusLockedIconStyles = ({ theme }: DefaultThemeProps) => css`
  color: ${theme.colors.base.textTitles};
`;
export const StatusLockedIcon = styled(Icon).attrs(() => ({
  variant: "lock",
}))`
  ${StatusLockedIconStyles};
`;
