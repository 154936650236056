import { useMemo } from "react";

import { ContentItem } from "@domain/types";
import useContentApi from "@hooks/useContentApi";
import useProgress from "@hooks/useProgress";
import { constructContentTree } from "@utils/helpers";
import { Node } from "@utils/tree/Node";

export default (options?: {
  useErrorBoundary: boolean;
}): {
  isLoading: boolean;
  content: Node<ContentItem | null> | undefined;
  error: unknown;
} => {
  const {
    isLoading: isLoadingProgress,
    data: progressData,
    error: progressError,
  } = useProgress(options);
  const {
    isLoading: isLoadingContent,
    data: contentData,
    error: contentError,
  } = useContentApi(options);

  const data = useMemo(() => {
    return constructContentTree(contentData || [], progressData || []);
  }, [progressData, contentData]);

  return {
    isLoading: isLoadingProgress || isLoadingContent,
    content: data,
    error: contentError || progressError,
  };
};
