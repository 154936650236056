import React from "react";

import ContentSlide from "@components/Onboarding/ContentSlide";
import VideoSlide from "@components/Onboarding/VideoSlide";
import { OnBoardingContentType, OnBoardingType, OnBoardingVideoType } from "@domain/types";

function isVideo(type: OnBoardingVideoType | OnBoardingContentType): type is OnBoardingVideoType {
  return (type as OnBoardingVideoType).type === OnBoardingType.VIDEO;
}

const OnboardingSlides = ({
  content,
}: {
  content: OnBoardingVideoType | OnBoardingContentType;
}) => {
  if (isVideo(content)) {
    return <VideoSlide content={content} />;
  }
  return <ContentSlide content={content} />;
};

export default OnboardingSlides;
