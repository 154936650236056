import type { IPlayerModel } from "@lumieducation/h5p-server";
import axios, { AxiosInstance } from "axios";

export interface IContentListEntry {
  contentId: string;
  mainLibrary: string;
  title: string;
  originalNewKey?: string;
}

export interface IContentClient {
  getPlay(contentId: string, token: string, signal: AbortSignal | undefined): Promise<IPlayerModel>;
}

export class H5PContentClient implements IContentClient {
  private readonly axios: AxiosInstance;

  private readonly baseURL?: string;

  constructor(baseURL: string) {
    this.baseURL = baseURL;
    this.axios = axios.create({
      timeout: 50_000,
      baseURL: this.baseURL,
      headers: {
        "App-Version": `${APPLICATION_KEY}:${APPLICATION_VERSION}`,
      },
    });
  }

  getPlay = async (
    contentId: string,
    token: string,
    signal: AbortSignal | undefined,
  ): Promise<IPlayerModel> => {
    const addAuthorization = window.didOverrideH5PXMLHttpRequest !== true;
    const res = await this.axios.get<IPlayerModel>(`${contentId}/play`, {
      headers: addAuthorization
        ? {
            Authorization: `Bearer ${token}`,
          }
        : undefined,
      signal,
    });
    return res.data;
  };
}
