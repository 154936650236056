import { HeadingNarrowMarketingRegularStyles } from "@components/typography/shared/Typography.styled";
import styled from "styled-components";

import Typography, { TypographyBaseProps } from "@anwb/typography";

export const HeadingNarrowMarketingRegular = styled(Typography)<TypographyBaseProps>`
  ${HeadingNarrowMarketingRegularStyles}
`;

export default HeadingNarrowMarketingRegular;
