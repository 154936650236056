import { HeadingCompressedBoldM } from "@components/typography";
import { IImageProps } from "@domain/types";
import { customVariables } from "@theme/custom-variables";
import styled, { css } from "styled-components";

import { pxToRem } from "@anwb/style-utilities";
import { DefaultThemeProps } from "@anwb/theme";

import { getBackgroundPositionCssForImageWithFocalPoint } from "@topgun/author-front-end/src/utils/focalPoint";
import { scaleBynderImage } from "@topgun/shared/src/util/image";

interface DefaultProps {
  $wide: boolean;
  $high: boolean;
}

const trainerTitleStyles = ({ theme }: DefaultThemeProps) => css`
  flex: 1;
  padding: 1.5rem 1.25rem;
  display: flex;
  align-items: center;
  text-align: left;

  && {
    @media (max-width: ${pxToRem(theme.viewportBreakpoint.sm)}) {
      word-break: break-all;
    }
  }
`;

export const TrainerTitle = styled(HeadingCompressedBoldM)`
  ${trainerTitleStyles};
`;

const aspectRatioStyles = ({ theme }: DefaultThemeProps) => css`
  aspect-ratio: 4 / 3;
  @media (min-width: ${pxToRem(theme.viewportBreakpoint.sm)}) {
    height: 400px;
  }
`;

const trainerCardWrapperStyles = ({
  theme,
  $wide,
  $high,
  image,
}: DefaultThemeProps & DefaultProps & IImageProps) => css`
  &:hover {
    background-color: ${customVariables.colors.trainerCardHover};
  }
  transition: ease-in-out 0.3s;
  border-radius: 0.75rem;
  background-color: ${customVariables.colors.trainerCard};
  background-size: cover;
  background-repeat: no-repeat;
  background-image: linear-gradient(rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.8) 100%),
    url(${image && scaleBynderImage(image.url)});
  ${getBackgroundPositionCssForImageWithFocalPoint(image)};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (min-width: ${pxToRem(theme.viewportBreakpoint.sm)}) {
    max-width: ${$wide ? "100%" : "600px"};
  }

  ${$high && aspectRatioStyles}
`;

export const TrainerCardWrapper = styled.div<DefaultProps & IImageProps>`
  ${trainerCardWrapperStyles};
`;

export const SubTitle = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 12px;
`;

export const Questions = styled.div`
  align-items: center;
  border: 1px solid #ffffff;
  border-radius: 0.75rem;
  display: flex;
  flex-direction: column;
  height: 75px;
  justify-content: center;
  margin: 4px;
  width: 50px;
`;

export const TrainerCardContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75em;
`;

export const trainerImageStyled = ({ image }: IImageProps) => css`
  display: block;
  height: 50%;
  border-radius: 0.75rem;
  background-image: linear-gradient(
      rgba(0, 15, 34, 0.5) 0%,
      rgba(0, 0, 0, 0) 50%,
      rgba(0, 15, 34, 0.75) 100%
    ),
    url(${image && scaleBynderImage(image.url)});
  background-size: cover;
  ${getBackgroundPositionCssForImageWithFocalPoint(image)}
  margin: 0.75rem;
`;
