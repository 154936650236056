import React, { ElementType } from "react";

import { marked } from "marked";

import { htmlSanitizer } from "@anwb/poncho/utilities/html-sanitizer";

interface MarkdownSanitizedProps {
  markdown: string;
  container: ElementType;
}

export function MarkdownSanitized({ markdown, container: Container }: MarkdownSanitizedProps) {
  const parsedText = marked.parse(markdown, { async: false });
  const sanitizedHtml = htmlSanitizer(parsedText);

  return <Container dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />;
}
