import { FunctionComponent, useEffect } from "react";

import { useAuth } from "oidc-react";

export const AuthTokenReloader: FunctionComponent = () => {
  const auth = useAuth();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has("reloadToken")) {
      auth.userManager
        .signinSilent()
        .then(() => {
          removeReloadParameterFromUrl(urlParams);
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error("Silent sign-in failed: ", error);
        });
    }
  }, [auth.userManager]);

  return null;
};

function removeReloadParameterFromUrl(urlParams: URLSearchParams) {
  urlParams.delete("reloadToken");

  window.history.replaceState(
    null,
    "",
    `${window.location.pathname}?${urlParams.toString()}${window.location.hash}`,
  );
}
