import {
  TYPOGRAPHY_COMPRESSED_BOLD,
  TYPOGRAPHY_NARROW_BOLD,
  TYPOGRAPHY_REGULARSEMI_BOLD,
} from "@utils/constants";
import { createGlobalStyle } from "styled-components";

import BerninaSansCompressed from "../fonts/BerninaSans-CompressedBold.otf";
import BerninaSansNarrow from "../fonts/BerninaSans-NarrowRegular.otf";
import BerninaSansNarrowSemibold from "../fonts/BerninaSans-NarrowSemibold.otf";
import ClanProCompBold from "../fonts/ClanPro-CompBold.otf";
import ClanProCompMedium from "../fonts/ClanPro-CompMedium.otf";
import ClanProMedium from "../fonts/ClanPro-Medium.otf";

export const CustomFonts = createGlobalStyle`
  @font-face {
    font-family: 'Bernina Sans Compressed';
    src: url(${BerninaSansCompressed}) format('opentype');
    font-weight: ${TYPOGRAPHY_COMPRESSED_BOLD};
  }

  @font-face {
    font-family: 'Bernina Sans Narrow';
    src: url(${BerninaSansNarrow}) format('opentype');
    font-weight: ${TYPOGRAPHY_NARROW_BOLD};
  }

  @font-face {
    font-family: 'Bernina Sans Narrow';
    src: url(${BerninaSansNarrowSemibold}) format('opentype');
    font-weight: ${TYPOGRAPHY_REGULARSEMI_BOLD};
  }

  @font-face {
    font-family: 'ClanPro Compressed Bold';
    src: url(${ClanProCompBold}) format('opentype');
    font-weight: ${TYPOGRAPHY_COMPRESSED_BOLD};
  }

  @font-face {
    font-family: 'ClanPro Compressed Medium';
    src: url(${ClanProCompMedium}) format('opentype');
    font-weight: ${TYPOGRAPHY_NARROW_BOLD};
  }

  @font-face {
    font-family: 'ClanPro Medium';
    src: url(${ClanProMedium}) format('opentype');
    font-weight: ${TYPOGRAPHY_NARROW_BOLD};
  }
`;
