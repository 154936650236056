import { ButtonTertiaryDark } from "@components/buttons";
import styled, { css } from "styled-components";

import { Image } from "@anwb/image";
import { pxToRem } from "@anwb/style-utilities";
import { Theme } from "@anwb/theme";

type DefaultThemeProps = {
  theme: Theme;
};

const ANWB_LOGO_WIDTH = 80;
const ANWB_LOGO_HEIGHT = 40;

const headerWrapperStyles = ({ theme }: DefaultThemeProps) => css`
  position: sticky;
  width: 100%;
  top: 0;
  display: flex;
  align-items: center;
  z-index: 10;
  max-width: 80rem;
  margin: 0 auto;
  padding: 1.5rem 2rem;

  @media (max-width: ${pxToRem(theme.viewportBreakpoint.sm)}) {
    justify-content: center;
    padding: 1rem;
  }

  &.fullWidth {
    position: fixed;
    width: 100%;
    max-width: unset;
  }
`;

export const HeaderWrapper = styled.div`
  ${headerWrapperStyles}
`;

const headerElementStyles = () => css`
  width: 33%;
  display: flex;
  flex-direction: row;
`;

export const HeaderLeftElement = styled.div`
  ${headerElementStyles};
  justify-content: start;
`;

const headerCenterElementStyles = ({ theme }: DefaultThemeProps) => css`
  margin: 0 auto;

  @media (max-width: ${pxToRem(theme.viewportBreakpoint.sm)}) {
    margin: 0;
    width: 100%;
  }
`;

export const HeaderCenterElement = styled.div`
  ${headerCenterElementStyles};
`;

export const HeaderRightElement = styled.div`
  ${headerElementStyles};
  justify-content: end;
  align-self: center;
`;

const anwbLogoImage = ({ theme }: DefaultThemeProps) => css`
  height: ${pxToRem(ANWB_LOGO_HEIGHT)};
  width: ${pxToRem(ANWB_LOGO_WIDTH)};
  margin: auto 0 auto auto;

  @media (max-width: ${pxToRem(theme.viewportBreakpoint.sm)}) {
    display: none;
  }
`;

export const AnwbLogoImage = styled(Image)`
  ${anwbLogoImage}
`;

export const NavigateBackWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const HeaderFlexContainer = styled.div`
  display: flex;
  height: 3rem;
  width: inherit;
  justify-content: center;
`;

const breadCrumbWrapperStyles = ({ theme }: DefaultThemeProps) => css`
  display: none;

  @media (min-width: ${pxToRem(theme.viewportBreakpoint.sm)}) {
    display: flex;
    gap: 1rem;
    margin-inline-start: 1rem;
  }
`;

export const BreadCrumbWrapper = styled.div`
  ${breadCrumbWrapperStyles}
`;

export const BreadCrumb = styled(ButtonTertiaryDark)`
  min-height: unset;
  height: 3rem;
  white-space: nowrap;
  overflow: hidden;
  width: unset;

  &:disabled {
    background-color: transparent !important;
    border: 1px solid transparent !important;
    cursor: auto;
  }
`;
