import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import ChapterNumberTag from "@components/ChapterNumberTag";
import ChapterProgressBar from "@components/ChapterProgressBar";
import { PageResponse } from "@domain/types";
import { animated, config, useSpring } from "@react-spring/web";

import {
  ChapterImageHeader,
  ChapterImageHeaderTitle,
  ChapterProgressBarWrapper,
  CurrentChapterImage,
} from "./styles";

type CurrentChapterCardProps = {
  recentAccessedPage: PageResponse;
};

const CurrentChapterCard: FunctionComponent<CurrentChapterCardProps> = ({ recentAccessedPage }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const styles = useSpring({
    from: {
      opacity: 0,
      transform: "translateY(50px)",
    },
    to: {
      opacity: 1,
      transform: "translateY(0px)",
    },
    config: config.wobbly,
  });

  const handleGoToChapter = (chapterId: string) => {
    navigate(`/chapters/${chapterId}`);
  };

  const {
    id: chapterId,
    title: chapterTitle,
    sequenceLabel,
    image,
  } = recentAccessedPage.chapter.value;

  return (
    <animated.div style={styles} data-testid="current-chapter-card">
      <CurrentChapterImage image={image} onClick={() => handleGoToChapter(chapterId)}>
        <ChapterImageHeader>
          {sequenceLabel && <ChapterNumberTag chapterNumber={sequenceLabel} />}
          <ChapterImageHeaderTitle tagName="h3">{chapterTitle}</ChapterImageHeaderTitle>
        </ChapterImageHeader>

        <ChapterProgressBarWrapper>
          <ChapterProgressBar
            remainedWithLabel={t("dashboard.theory.progressProceedWithCta")}
            recentAccessedPage={recentAccessedPage}
          />
        </ChapterProgressBarWrapper>
      </CurrentChapterImage>
    </animated.div>
  );
};

export default CurrentChapterCard;
