import { FunctionComponent, HTMLAttributes } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import {
  Questions,
  SubTitle,
  TrainerCardContentWrapper,
  TrainerCardWrapper,
  TrainerTitle,
} from "@components/TrainerCard/styles";
import { ArrowIcon, ButtonPrimaryDark, SecondaryNavigationButtonIcon } from "@components/buttons";
import { BodyS, SubheadingM, SubheadingS } from "@components/typography";
import { IConfiguration } from "@domain/types";
import { animated, useSpring } from "@react-spring/web";

type TrainerCardProps = {
  trainer: IConfiguration;
  wide?: boolean;
  high?: boolean;
  withButton?: boolean;
  title?: string;
  isPracticeExamButton?: boolean;
} & HTMLAttributes<HTMLDivElement>;

const ANIMATION_OFFSET_START = 0;
const ANIMATION_OFFSET_END = -10;
const TrainerCard: FunctionComponent<TrainerCardProps> = ({
  trainer,
  wide = false,
  high = true,
  withButton = false,
  title,
  isPracticeExamButton = false,
  ...props
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [springs, api] = useSpring(() => ({
    from: { y: 0 },
  }));

  const handleGoToTrainer = (trainerId: string) => {
    navigate(`/trainers/${trainerId}`);
  };

  const handleMouse = (from: number, to: number) => {
    api.start({
      from: {
        y: from,
      },
      to: {
        y: to,
      },
    });
  };

  return (
    <animated.div
      onMouseEnter={() => handleMouse(ANIMATION_OFFSET_START, ANIMATION_OFFSET_END)}
      onMouseLeave={() => handleMouse(ANIMATION_OFFSET_END, ANIMATION_OFFSET_START)}
      style={{
        ...springs,
      }}
    >
      <TrainerCardWrapper
        data-test="trainer-card"
        image={trainer.image}
        $wide={wide}
        $high={high}
        role="link"
        onClick={() => handleGoToTrainer(trainer.id)}
        {...props}
      >
        <TrainerTitle>{title || trainer.title}</TrainerTitle>

        <TrainerCardContentWrapper>
          <SubTitle>
            <Questions>
              <SubheadingS>{trainer.totalAmount}</SubheadingS>
              <BodyS>
                {t("trainersOverview.trainerCard.questions", { count: trainer.totalAmount })}
              </BodyS>
            </Questions>
            <SubheadingM>{trainer.subtitle}</SubheadingM>
          </SubTitle>
          {withButton && <SecondaryNavigationButtonIcon />}
          {isPracticeExamButton && (
            <ButtonPrimaryDark icon={ArrowIcon.RIGHT}>
              {t("dashboard.practiceExam.buttonLabel")}
            </ButtonPrimaryDark>
          )}
        </TrainerCardContentWrapper>
      </TrainerCardWrapper>
    </animated.div>
  );
};

export default TrainerCard;
