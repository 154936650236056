import { useClient } from "@components/ClientProvider";
import { useUser } from "@hooks/useUser";
import { getContentQueryKey } from "@hooks/utils";
import { useQuery } from "@tanstack/react-query";
import { filterInvalidType } from "@utils/helpers";

export default (options?: { useErrorBoundary: boolean }) => {
  const user = useUser();
  const { dataClient } = useClient();

  return useQuery({
    queryKey: getContentQueryKey(user),
    enabled: !!user,
    queryFn: async ({ signal }) => {
      if (!user) {
        throw new Error("Unauthenticated");
      }
      const response = await dataClient.getContent(user.id, user.accessToken, signal);

      return filterInvalidType(response);
    },
    staleTime: 1000 * 60 * 5,
    keepPreviousData: true,
    ...(options || {}),
  });
};
