import { useMemo } from "react";

import { ContentStatusHelper } from "@helpers/ContentStatusHelper";
import useCourse from "@hooks/useCourse";

import { ContentType } from "@topgun/shared/src/types/frontend";

export default (chapterId: string | undefined) => {
  const { course, ...courseData } = useCourse();

  const chapterData = useMemo(() => {
    if (!course || !chapterId) {
      return {};
    }

    const chapter = course.children.find(
      (child) => child.value.id === chapterId && child.value.type === ContentType.CHAPTER,
    );

    return {
      chapter,
      sections: chapter?.children,
      progress: chapter ? ContentStatusHelper.getActivityProgressPercentage(chapter) : undefined,
    };
  }, [course, chapterId]);

  return {
    ...courseData,
    ...chapterData,
  };
};
