import { useClient } from "@components/ClientProvider";
import { getProgressQueryKey } from "@hooks/utils";
import { useQuery } from "@tanstack/react-query";
import { useAuth } from "oidc-react";

export default (options?: { useErrorBoundary: boolean }) => {
  const { userData } = useAuth();
  const { dataClient } = useClient();

  const { access_token: accessToken, profile } = userData || {};
  const { sub: userId } = profile || {};

  return useQuery({
    queryKey: getProgressQueryKey(userId, accessToken),
    enabled: !!accessToken && !!userId,
    queryFn: async ({ signal }) => {
      if (!userId) {
        throw new Error("Unknown user");
      }
      if (!accessToken) {
        throw new Error("Unauthenticated");
      }
      return dataClient.getUserProgress(userId, signal, accessToken);
    },
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    staleTime: 1000,
    ...(options || {}),
  });
};
