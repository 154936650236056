import { FunctionComponent, createContext, useContext, useMemo } from "react";

import { useConfigContext } from "@components/ConfigLoader";
import { H5PContentClient } from "@services/H5PContentClient";
import { DataClient } from "@services/api";

interface Clients {
  dataClient: DataClient;
  h5pClient: H5PContentClient;
}

export const ClientContext = createContext<Clients>({
  dataClient: new Proxy(new DataClient(""), {
    get: () => {
      throw new Error("Use ClientProvider to create an DataAPI object");
    },
  }),
  h5pClient: new Proxy(new H5PContentClient(""), {
    get: () => {
      throw new Error("Use ClientProvider to create an H5PContentClient object");
    },
  }),
});
export const useClient = () => useContext(ClientContext);

export const ClientProvider: FunctionComponent = ({ children }) => {
  const { apiUrl, h5pUrl } = useConfigContext();
  const client = useMemo(() => {
    return {
      dataClient: new DataClient(apiUrl),
      h5pClient: new H5PContentClient(h5pUrl),
    };
  }, [apiUrl, h5pUrl]);
  return <ClientContext.Provider value={client}>{children}</ClientContext.Provider>;
};
