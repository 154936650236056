import { FunctionComponent } from "react";

import {
  CallToActionButton,
  CallToActionCard,
  CallToActionContent,
  CallToActionGradientBorder,
  CallToActionList,
  CallToActionTitle,
} from "@components/CallToActionBlock/styles/callToActionBlock.styled";
import { animated, useSpring } from "@react-spring/web";
import callToActionImage from "shared/cta/call-to-action-image.png";

const ANIMATION_OFFSET_START = 0;
const ANIMATION_OFFSET_END = -10;

export type ContainerSize = "large" | "small";

const CallToActionBlock: FunctionComponent<{ containerSize: ContainerSize }> = ({
  containerSize,
}) => {
  const [springs, api] = useSpring(() => ({
    from: { y: 0 },
  }));

  const handleMouse = (from: number, to: number) => {
    api.start({
      from: {
        y: from,
      },
      to: {
        y: to,
      },
    });
  };

  return (
    <animated.div
      onMouseEnter={() => handleMouse(ANIMATION_OFFSET_START, ANIMATION_OFFSET_END)}
      onMouseLeave={() => handleMouse(ANIMATION_OFFSET_END, ANIMATION_OFFSET_START)}
      style={{
        ...springs,
      }}
    >
      <CallToActionGradientBorder href="https://www.anwb.nl/auto/rijbewijs/theorie" target="_blank">
        <CallToActionCard backgroundImg={callToActionImage as string}>
          <CallToActionTitle>
            Leren op jouw manier met <span>ANWB Theorie</span>
          </CallToActionTitle>
          <CallToActionContent>
            Je{" "}
            <strong>
              <u>eigen</u>
            </strong>{" "}
            verkeerskennis opfrissen of heb je een <strong>(klein)kind</strong> dat wil slagen voor
            het CBR theorie-examen? Bestel nu de volledige versie van ANWB Theorie:
          </CallToActionContent>
          <CallToActionList>
            <li>Verkeerstheorie makkelijk uitgelegd</li>
            <li>Onbeperkt oefenexamens</li>
            <li>Meer dan 150 uitlegvideo&apos;s</li>
            <li>2 maanden toegang</li>
            <li>
              Ontvang nu 10% korting met de actiecode <strong>HEREXAMENK2</strong>. Voer de code in
              bij het afrekenen.
            </li>
          </CallToActionList>
          <CallToActionButton icon="arrow-right" containerSize={containerSize}>
            Meer informatie
          </CallToActionButton>
        </CallToActionCard>
      </CallToActionGradientBorder>
    </animated.div>
  );
};

export default CallToActionBlock;
