import { FunctionComponent, useReducer } from "react";

import {
  HeaderContext,
  HeaderUpdaterContext,
  tasksReducer,
} from "@components/HeaderContextProvider/context";

export const HeaderContextProvider: FunctionComponent = ({ children }) => {
  const [state, dispatch] = useReducer(tasksReducer, {
    showHeaderLogo: true,
    showHeaderMenu: true,
    showOnboarding: false,
    navigateBack: undefined,
    breadCrumbs: undefined,
    confirmPageLeave: false,
    confirmPageLeaveOptions: undefined,
  });

  return (
    <HeaderContext.Provider value={state}>
      <HeaderUpdaterContext.Provider value={dispatch}>{children}</HeaderUpdaterContext.Provider>
    </HeaderContext.Provider>
  );
};
