import HeadingCompressedBoldL from "@components/typography/HeadingCompressedBoldL";
import { customVariables } from "@theme/custom-variables";
import styled, { css } from "styled-components";

import { pxToRem } from "@anwb/style-utilities";
import { Theme } from "@anwb/theme";

import { getBackgroundPositionCssForImageWithFocalPoint } from "@topgun/author-front-end/src/utils/focalPoint";
import { AuthorImageProps } from "@topgun/shared/src/types/frontend";
import { scaleBynderImage } from "@topgun/shared/src/util/image";

type DefaultThemeProps = {
  theme: Theme;
};

type ChapterImageProps = {
  image?: AuthorImageProps;
};

const currentChapterImageStyles = ({ theme, image }: DefaultThemeProps & ChapterImageProps) => css`
  background-image: linear-gradient(
      180deg,
      rgba(0, 15, 34, 0.5) 0%,
      rgba(0, 0, 0, 0) 50%,
      rgba(0, 15, 34, 0.75) 100%
    ),
    url(${image && scaleBynderImage(image.url)});

  background-size: cover;
  ${getBackgroundPositionCssForImageWithFocalPoint(image)}

  background-repeat: no-repeat;
  background-blend-mode: darken;
  aspect-ratio: 920 / 533;
  border-radius: 0.75rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;

  @media (max-width: ${pxToRem(theme.viewportBreakpoint.md)}) {
    aspect-ratio: 5 / 3;
  }

  @media (max-width: ${pxToRem(theme.viewportBreakpoint.sm)}) {
    background-image: linear-gradient(
        180deg,
        rgba(0, 15, 34, 0.5) 0%,
        rgba(0, 0, 0, 0) 50%,
        rgba(0, 15, 34, 0.75) 100%
      ),
      url(${image && scaleBynderImage(image.url)});

    aspect-ratio: 4 / 3;
  }
`;

export const CurrentChapterImage = styled.div<{ image?: AuthorImageProps }>`
  ${currentChapterImageStyles}
`;

const chapterImageHeaderStyling = ({ theme }: DefaultThemeProps) => css`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: start;
  padding: 1rem;

  @media (min-width: ${pxToRem(theme.viewportBreakpoint.sm)}) {
    flex-direction: row;
    align-items: center;
  }
`;

export const ChapterImageHeader = styled.div`
  ${chapterImageHeaderStyling}
`;

export const ChapterImageHeaderTitle = styled(HeadingCompressedBoldL)``;

const chapterProgressBarWrapperStyles = ({ theme }: DefaultThemeProps) => css`
  padding: 0.5rem 1rem;

  @media (max-width: ${pxToRem(theme.viewportBreakpoint.sm)}) {
    padding: 1rem;
    background: linear-gradient(
      180deg,
      rgba(0, 21, 47, 0) 0%,
      ${customVariables.colors.primaryBackground} 86.78%
    );
    opacity: 0.8;
  }
`;

export const ChapterProgressBarWrapper = styled.div`
  ${chapterProgressBarWrapperStyles}
`;
