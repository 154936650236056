import { FunctionComponent } from "react";

import { Illustration } from "@components/CheckOutTitleWithIllustration/styles";
import activityIllustration from "shared/activity_illustration.svg";
import failedPracticeExamIllustration from "shared/failed_practice_exam.svg";
import keepPracticingIllustration from "shared/keep_practicing_illustration.svg";
import passedPracticeExamIllustration from "shared/passed_practice_exam.svg";
import passedTrainerIllustration from "shared/passed_trainer_illustration.svg";
import segmentKeepGoingIllustration from "shared/segment_keep_going_illustration.svg";
import segmentSuccessIllustration from "shared/segment_success_illustration.svg";
import segmentWellDoneIllustration from "shared/segment_well_done_illustration.svg";

export enum CheckOutType {
  ACTIVITY_CHECKOUT_FINISHED,
  TRAINER_CHECKOUT_SUCCESS,
  TRAINER_CHECKOUT_FAILURE,
  TRAINER_SEGMENT_STATUS_SUCCESS,
  TRAINER_SEGMENT_STATUS_KEEP_GOING,
  TRAINER_SEGMENT_STATUS_WELL_DONE,
  PRACTICE_EXAM_STATUS_SUCCESS,
  PRACTICE_EXAM_STATUS_FAILURE,
}
type CheckOutProps = {
  checkOutType: CheckOutType;
};

const imagesPerCheckOutType: Record<CheckOutType, string> = {
  [CheckOutType.ACTIVITY_CHECKOUT_FINISHED]: activityIllustration,
  [CheckOutType.TRAINER_CHECKOUT_SUCCESS]: passedTrainerIllustration,
  [CheckOutType.TRAINER_CHECKOUT_FAILURE]: keepPracticingIllustration,
  [CheckOutType.TRAINER_SEGMENT_STATUS_SUCCESS]: segmentSuccessIllustration,
  [CheckOutType.TRAINER_SEGMENT_STATUS_KEEP_GOING]: segmentKeepGoingIllustration,
  [CheckOutType.TRAINER_SEGMENT_STATUS_WELL_DONE]: segmentWellDoneIllustration,
  [CheckOutType.PRACTICE_EXAM_STATUS_SUCCESS]: passedPracticeExamIllustration,
  [CheckOutType.PRACTICE_EXAM_STATUS_FAILURE]: failedPracticeExamIllustration,
};

const CheckOutTitleWithIllustration: FunctionComponent<CheckOutProps> = ({ checkOutType }) => {
  return <Illustration checkOutType={checkOutType} src={imagesPerCheckOutType[checkOutType]} />;
};

export default CheckOutTitleWithIllustration;
