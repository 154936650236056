import { useClient } from "@components/ClientProvider";
import { IConfiguration } from "@domain/types";
import { getConfigurationQueryKey, getConfigurationsQueryKey } from "@hooks/utils";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useAuth } from "oidc-react";

export default (
  types: string[],
  belongsTo: string | undefined,
  options?: { useErrorBoundary: boolean },
): {
  isLoading: boolean;
  error: unknown;
  dynamicActivityConfigurations: IConfiguration[] | undefined;
} => {
  const { userData } = useAuth();
  const { dataClient } = useClient();
  const queryClient = useQueryClient();
  const { access_token: accessToken } = userData || {};

  const { isLoading, error, data } = useQuery({
    queryKey: getConfigurationsQueryKey(types, belongsTo, accessToken),
    enabled: !!accessToken && belongsTo !== undefined,
    queryFn: async ({ signal }) => {
      if (!accessToken) {
        throw new Error("Missing access token");
      }

      const result = await dataClient.getDynamicActivityConfigurations(signal, accessToken, {
        type: types,
        belongs_to: belongsTo,
      });

      // preload cache for detail page
      result.forEach((configuration) => {
        queryClient.setQueryData(
          getConfigurationQueryKey(configuration.id, accessToken),
          configuration,
        );
      });

      return result;
    },
    ...(options || {}),
  });

  return {
    isLoading,
    error,
    dynamicActivityConfigurations: data,
  };
};
