import React from "react";
import { render } from "react-dom";

import Application from "@components/Application";
import makeServer from "@mocks/server";
import { OAUTH_KEY } from "@utils/constants";

import NewRelicHelper from "@topgun/shared/src/helper/NewRelicHelper";

NewRelicHelper.setRelease(APPLICATION_KEY, APPLICATION_VERSION);

const root = document.getElementById("topgun-end-user-front-end") as HTMLElement;
root.dataset.oauthStorageKey = OAUTH_KEY;

if (
  process.env.USE_MIRAGE === "true" &&
  process.env.NODE_ENV === "development" &&
  typeof makeServer === "function"
) {
  root.dataset.useMirage = "true";
  makeServer();
}

render(<Application />, root);
