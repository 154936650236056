import { CheckOutType } from "@components/CheckOutTitleWithIllustration/CheckOutTitleWithIllustration";
import styled, { css } from "styled-components";

import { Image } from "@anwb/image";
import { pxToRem } from "@anwb/style-utilities";
import { DefaultThemeProps } from "@anwb/theme";

const illustrationAndTitleStyling = () => css`
  margin: 0;
`;

const illustrationTrainerCheckOutSuccess = ({ theme }: DefaultThemeProps) => css`
  ${illustrationAndTitleStyling};
  margin-bottom: 0;

  @media (min-width: ${pxToRem(theme.viewportBreakpoint.md)}) {
    margin-bottom: 12rem;
  }
  @media (min-width: ${pxToRem(theme.viewportBreakpoint.lg)}) {
    margin-bottom: 14rem;
  }
`;

const illustrationStyles = ({
  checkOutType,
  theme,
}: DefaultThemeProps & { checkOutType: CheckOutType }) => {
  if (checkOutType === CheckOutType.TRAINER_CHECKOUT_SUCCESS) {
    return illustrationTrainerCheckOutSuccess({ theme });
  }
  return illustrationAndTitleStyling();
};

export const Illustration = styled(Image).attrs({
  "aria-hidden": true,
})`
  ${(props: DefaultThemeProps & { checkOutType: CheckOutType }) => illustrationStyles(props)};
`;
