import { HeadingCompressedBoldXSStyles } from "@components/typography/shared/Typography.styled";
import styled from "styled-components";

import Typography, { TypographyBaseProps } from "@anwb/typography";

export const HeadingCompressedBoldXS = styled(Typography)<TypographyBaseProps>`
  ${HeadingCompressedBoldXSStyles}
`;

export default HeadingCompressedBoldXS;
