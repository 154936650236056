import { useClient } from "@components/ClientProvider";
import { IConfiguration } from "@domain/types";
import { getDynamicActivityQueryKey } from "@hooks/utils";
import { useQuery } from "@tanstack/react-query";
import { useAuth } from "oidc-react";

export default (
  dynamicActivityId: string,
): {
  isLoading: boolean;
  error: unknown;
  dynamicActivity: IConfiguration | undefined;
} => {
  const { userData } = useAuth();
  const { dataClient } = useClient();
  const { access_token: accessToken } = userData || {};

  const { isLoading, error, data } = useQuery({
    queryKey: getDynamicActivityQueryKey(dynamicActivityId, accessToken),
    enabled: !!accessToken,
    queryFn: async ({ signal }) => {
      if (!accessToken) {
        throw new Error("Missing access token");
      }
      return dataClient.getDynamicActivity(dynamicActivityId, signal, accessToken);
    },
    staleTime: 1000 * 60 * 5,
  });

  return {
    isLoading,
    error,
    dynamicActivity: data,
  };
};
