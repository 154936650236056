import { useContext, useMemo } from "react";

import { HeaderContext, HeaderUpdaterContext } from "@components/HeaderContextProvider/context";
import { BreadCrumb, ConfirmPageLeaveOptions, NavigateBackProps } from "@domain/types";

export const useHeaderUpdater = () => {
  const dispatch = useContext(HeaderUpdaterContext);
  if (dispatch === undefined) {
    throw new Error("useHeaderUpdater was used outside of its Provider");
  }

  return useMemo(() => {
    return {
      setShowHeaderLogo: (show: boolean) => dispatch({ type: "header_logo", value: show }),
      setShowHeaderMenu: (show: boolean) => dispatch({ type: "header_menu", value: show }),
      setShowOnBoarding: (show: boolean) => dispatch({ type: "onboarding", value: show }),
      clearNavigateBack: () => dispatch({ type: "navigate_back", value: undefined }),
      setNavigateBack: (back: NavigateBackProps) =>
        dispatch({ type: "navigate_back", value: back }),
      setBreadcrumbs: (crumbs: BreadCrumb[]) => dispatch({ type: "breadcrumb", value: crumbs }),
      clearBreadcrumbs: () => dispatch({ type: "breadcrumb", value: undefined }),
      enableLeaveConfirmation: (options?: ConfirmPageLeaveOptions) =>
        dispatch({ type: "confirm_page_leave", value: true, options }),
      disableLeaveConfirmation: () => dispatch({ type: "confirm_page_leave", value: false }),
      setLeaveConfirmationOptions: (options: ConfirmPageLeaveOptions) =>
        dispatch({ type: "confirm_page_leave_options", value: options }),
    };
  }, [dispatch]);
};

export const useHeaderState = () => {
  const context = useContext(HeaderContext);

  if (context === undefined) {
    throw new Error("useHeaderState was used outside of its Provider");
  }

  return context;
};
