import styled, { css } from "styled-components";

import Grid from "@anwb/grid";
import { pxToRem } from "@anwb/style-utilities";
import { Theme } from "@anwb/theme";

type DefaultThemeProps = {
  theme: Theme;
};

const CHAPTER_BACKGROUND_WRAPPER_TOP_MOBILE = -75;

export const resultContainerStyles = ({ theme }: DefaultThemeProps) => css`
  background: linear-gradient(180deg, #001b3c 0%, #060e1b 86.78%);
  height: 100vh;
  padding-top: 6rem;
  position: absolute;
  top: 0;
  width: 100vw;

  @media (max-width: ${pxToRem(theme.viewportBreakpoint.sm)}) {
    top: ${pxToRem(CHAPTER_BACKGROUND_WRAPPER_TOP_MOBILE)};
  }
`;

export const ResultsBackground = styled.div<DefaultThemeProps>`
  ${resultContainerStyles};
`;
export const GridContainer = styled(Grid)`
  place-content: center;
`;
export const CheckOutGridItem = styled(Grid.Item)`
  align-self: center;
`;

export const IllustrationWrapper = styled(CheckOutGridItem)`
  text-align: center;
  display: flex;
  justify-content: center;
`;

const contentWrapperStyles = ({ theme }: DefaultThemeProps) => css`
  display: flex;
  margin: 0 auto;
  max-width: 80rem;
  width: 100%;
  padding: 0 2rem 0;
  height: 100%;

  @media (max-width: ${pxToRem(theme.viewportBreakpoint.sm)}) {
    flex-direction: column;
    padding-top: 4rem;
    padding-inline: 1rem;
  }
`;

export const ContentWrapper = styled.div`
  ${contentWrapperStyles};
`;

export const buttonWrapperStyles = ({ theme }: DefaultThemeProps) => css`
  display: flex;
  flex-direction: row;
  padding-top: 1rem;
  gap: 1.5rem;

  @media (max-width: ${pxToRem(theme.viewportBreakpoint.md)}) {
    flex-direction: column;
    width: 100%;
    gap: 1rem;
  }
`;

export const ButtonWrapper = styled.div`
  ${buttonWrapperStyles};
`;
