import { ContentItem } from "@domain/types";
import { Node } from "@utils/tree/Node";

import { ContentType } from "@topgun/shared/src/types/frontend";

export class ContentSequenceHelper {
  public static setSequenceLabels(content: Node<ContentItem | null>): void {
    for (const course of content.filterBreadthFirst((item) => item?.type === ContentType.COURSE)) {
      let chapterCounter = 1;

      for (const chapter of course.filterBreadthFirst(
        (item) => item?.type === ContentType.CHAPTER,
      )) {
        if (!chapter.hasValue()) {
          continue;
        }
        chapter.value.sequenceLabel = `${chapterCounter}`;

        let activityCounter = 1;
        for (const activity of chapter.filterBreadthFirst(
          (item) => item?.type === ContentType.ACTIVITY,
        )) {
          if (!activity.hasValue()) {
            continue;
          }
          activity.value.sequenceLabel = `${chapterCounter}.${activityCounter}`;
          activityCounter += 1;
        }

        chapterCounter += 1;
      }
    }
  }
}
