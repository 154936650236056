import CenteredSpinner from "@components/CenteredSpinner";
import { useAuth } from "oidc-react";

function SignOut() {
  const { signOut } = useAuth();
  void signOut();

  return <CenteredSpinner size="large" />;
}

export default SignOut;
