import { FunctionComponent, ReactNode } from "react";

import {
  StyleRefreshWrapper,
  VideoWrapper,
} from "@components/Player/H5PPlayerWrapper/styles/h5p-player-wrapper.styled";

type Props = {
  contentType: string;
  children: ReactNode;
};

const WIDE_CONTENT_TYPES = ["H5P.ANWBContentTypeVideo"];
const VIDEO_CONTENT_TYPES = ["H5P.ANWBContentTypeVideo"];

const H5PPlayerWrapper: FunctionComponent<Props> = ({ contentType, children }) => {
  const className = `wrapper-${contentType.replace(".", "-")}`;

  if (VIDEO_CONTENT_TYPES.includes(contentType)) {
    return <VideoWrapper className={className}>{children}</VideoWrapper>;
  }

  return (
    <StyleRefreshWrapper $wide={WIDE_CONTENT_TYPES.includes(contentType)} className={className}>
      {children}
    </StyleRefreshWrapper>
  );
};

export default H5PPlayerWrapper;
