import { initReactI18next } from "react-i18next";

import i18n from "i18next";

/* eslint-disable @typescript-eslint/no-floating-promises */
i18n.use(initReactI18next).init({
  react: {
    transSupportBasicHtmlNodes: true,
    transKeepBasicHtmlNodesFor: ["a"],
  },
  debug: process.env.NODE_ENV === "development",
  lng: "nl",
  supportedLngs: ["nl"],
  fallbackLng: "nl",
  load: "languageOnly",
  interpolation: {
    escapeValue: false,
  },
  resources: {
    nl: {
      translation: {
        logout: "Uitloggen",
        login: {
          username: "Gebruikersnaam",
          password: "Wachtwoord",
          rememberMe: "Onthoud mijn inlognaam",
          loginCta: "Inloggen",
        },
        notifications: {
          type: {
            trainers: "trainers",
            user: "gebruiker",
            activity: "activiteit",
            chapter: "hoofdstukken",
          },
          videoError: {
            title: "Er is iets fout gegaan",
            text: "Er is iets fout gegaan bij het afspelen van de video. Probeer het later nog eens.",
          },
          error: {
            title: "Probleem met ophalen van de {{ materialType }}",
            text: "Het ophalen van de {{ materialType }} is helaas niet goed gegaan.",
          },
          configurationLoadingError: {
            title: "De app kan niet worden gestart",
            text: "De app kan niet opstarten, omdat de configuratie niet geladen kan worden. Probeer het a.u.b. opnieuw door de pagina te herladen.",
          },
          notFound: {
            title: "Pagina niet gevonden",
            text: "De gevraagde pagina is niet gevonden. Controleer de URL en probeer het opnieuw.",
          },
          licenceError: {
            title: "Geen geldige licentie",
            text: "Je hebt geen geldige licentie (meer). Verleng je licentie op <0>www.anwbtheorie.nl</0> en probeer het opnieuw.",
            url: "https://www.anwbtheorie.nl",
          },
          genericError: {
            title: "Er ging iets mis",
            text: "Er is iets misgegaan met het ophalen van de pagina.",
          },
          authError: {
            title: "Er ging iets mis",
            text: "Er is een fout opgetreden bij het ophalen van jouw gegevens.",
          },
          updateAvailable: {
            text: "Er is een update beschikbaar. Wil je de nieuwste versie installeren?",
            installLabel: "Installeer",
          },
          TypeError: {
            text: "Er is een fout in de code opgetreden",
          },
        },
        dashboard: {
          chapters: {
            title: "Kijk vooruit",
            subtitle: "Behoefte aan wat anders? Kijk hier vooruit.",
            buttonLabel: "Alle hoofdstukken",
          },
          practiceExam: {
            title: "Oefenexamen",
            subtitle: "Klaar om jouw kennis te toetsen? Oefen hier je examen.",
            buttonLabel: "Naar oefenexamen",
          },
          theory: {
            subtitle: "Ga direct aan de slag!",
            greeting: "Welkom",
            progressProceedWithCta: "Gebleven bij:",
          },
          trainers: {
            title: "Trainers",
            subtitle: "Speciaal voor jou om jouw leerdoelen te behalen",
            overviewCta: "Snel naar overzicht",
          },
        },
        trainerResult: {
          champion: "Kampioen",
          keepPracticing: "Blijven oefenen",
          correctQuestions1: `Van de {{amount}} vragen had jij`,
          amountCorrect: `{{amountCorrect}} goed`,
          amountIncorrect: `{{amountIncorrect}} fout`,
          tryAgain: "Opnieuw",
          toDashboard: "Naar dashboard",
        },
        practiceExamResult: {
          passedPracticeExam: "Geslaagd",
          failedPracticeExam: "Onvoldoende",
        },
        trainerSegments: {
          introductionTitle: "Nu volgt het examenonderdeel:",
          introductionButtonStart: "Starten met {{segmentTitle}}",
          introductionButtonBack: "Stap terug",
          illustrationStatusSuccess: "Succes",
          illustrationStatusKeepGoing: "Lekker bezig",
          illustrationStatusWellDone: "Goed gedaan",
          textStatusFinished: "Afgerond",
          textStatusNext: "Volgende",
          buttonStatusNext: "Naar uitleg",
        },
        trainersOverview: {
          title: "Trainers",
          subtitle:
            "Om de stof beter te begrijpen hebben wij een aantal oefenmodules voor je klaar gezet!",
          start: "Start",
          trainerCard: {
            questions_one: "vraag",
            questions_other: "vragen",
          },
        },
        activityCheckout: {
          finished: "Afgerond",
          whatIsNext: "Wat wil je doen?",
          toDashboard: "Naar dashboard",
          startWith: "Start met {{activityTitle}}",
          startChapterTest: "Starten met hoofdstuktoets",
          navigationOptionsTitle: "Of liever...",
        },
        chapterOverview: {
          progressProceedWithTitle: "theorie",
          progressProceedWith: "Verder waar je gebleven was:",
          remainInPageCta: "of browse alle hoofdstukken",
          remainInPageCtaMobile: "alle hoofdstukken",
          remainInPageCtaArrowAlt: "Verwijzing naar alle hoofdstukken",
          partsLabel_one: "onderdeel",
          partsLabel_other: "onderdelen",
        },
        chapterDetails: {
          progressProceedWithCta: "Ga verder met:",
          allActivitiesDone: "Alle activiteiten in dit hoofdstuk zijn afgerond.",
        },
        menu: {
          dashboard: "Dashboard",
          chapters: "Theorie",
          trainers: "Trainers",
          logout: "Uitloggen",
          practiceExam: "Oefenexamen",
        },
        header: {},
        status: {
          OPEN: "Nog niet begonnen",
          DOING: "Mee bezig",
          DONE: "Afgerond",
        },
        loading: "Laden ...",
        dashboardPage: "Dashboard",
        course: "Cursus",
        chapterPage: "Hoofdstuk",
        chapterTest: "Hoofdstuktoets",
        section: "Sectie",
        activity: "Activiteit",
        page: "Pagina",
        toHomeCta: "Terug naar homepagina",
        modal: {
          leavePage: {
            title: "Je verlaat hiermee de pagina",
            description: "Weet je het zeker?",
            confirmButton: "Ja",
            cancelButton: "Annuleer",
          },
          exitActivityTitle: "Je verlaat hiermee de les",
          exitActivityDescription: "Weet je het zeker?",
          exitActivityConfirm: "Ja, les verlaten",
          exitActivityCancel: "Annuleer",
          exitToDashboardActivityTitle: "Je keert hiermee terug naar het dashboard",
          exitToDashboardActivityDescription: "Weet je het zeker?",
          exitToDashboardActivityConfirm: "Ja, les verlaten en terug naar het dashboard",
          exitToDashboardActivityCancel: "Annuleer",
          restartActivityTitle: "Je start hiermee de les opnieuw",
          restartActivityDescription: "Weet je het zeker?",
          restartActivityConfirm: "Ja, start de les opnieuw",
          restartActivityCancel: "Annuleer",
          exitChapterTestTitle: "Je verlaat hiermee de hoofdstuktoets",
          exitChapterTestConfirm: "Ja, stoppen met de toets",
          exitPractiseExamTitle: "Je verlaat hiermee het oefenexamen",
          exitPractiseExamConfirm: "Ja, stoppen met het examen",
          exitResultsTitle: "Je verlaat hiermee de resultaten",
          exitResultsDescription: "Weet je het zeker?",
        },
        onboarding: {
          closeOnboarding: "Sluiten",
          nextSlide: "Volgende",
          finishOnboarding: "Aan de slag!",
        },
      },
    },
    en: {
      translation: {
        logout: "Log out",
        login: {
          username: "User name",
          password: "Password",
          rememberMe: "Remember me",
          loginCta: "Login",
        },
        notifications: {
          type: {
            trainers: "trainers",
            user: "user",
            activity: "activity",
            chapter: "chapter",
          },
          videoError: {
            title: "Something went wrong",
            text: "Something went wrong while playing the video. Please try again later.",
          },
          error: {
            title: "The {{ materialType }} retrieval went wrong",
            text: "The {{ materialType }} was not collected successfully.",
          },
          configurationLoadingError: {
            title: "The application failed to load",
            text: "The application failed to start because the configuration failed to load. Please try again by reloading the page.",
          },
          notFound: {
            title: "Page not found",
            text: "The requested page was not found. Check the URL and try again.",
          },
          licenceError: {
            title: "No valid license",
            text: "You do not have a valid license (anymore). Renew your license at <0>www.anwbtheorie.nl}</0> and try again.",
            url: "https://www.anwbtheorie.nl",
          },
          authError: {
            title: "An error occurred",
            text: "An error occurred while retrieving your user data.",
          },
          updateAvailable: {
            text: "An update is available. Do you want to install the latest version?",
            installLabel: "Install",
          },
          TypeError: {
            text: "A code error occurred",
          },
        },
        dashboard: {
          practiceExam: {
            title: "Practice exam",
            subtitle: "Are you ready to test your knowledge? Practice your exam here.",
            buttonLabel: "To practice-exam",
          },
          theory: {
            subtitle: "Get started right away!",
            greeting: "Welcome",
            overviewCta: "All chapters",
            proceedWith: "Proceed with:",
            whatsNext: "What's next?",
            progressProceedWithCta: "Remained with:",
          },
          trainers: {
            title: "Trainers",
            subtitle: "Especially for you to achieve your learning goals",
            overviewCta: "Go to overview",
          },
        },
        trainerResult: {
          champion: "Champion",
          keepPracticing: "Keep practicing",
          correctQuestions1: `Out of {{amount}} questions you had`,
          amountCorrect: `{{amountCorrect}} correct`,
          amountIncorrect: `{{amountIncorrect}} incorrect`,
          tryAgain: "Try again",
          toDashboard: "To dasboard",
        },
        practiceExamResult: {
          passedPracticeExam: "Passed",
          failedPracticeExam: "Failed",
        },
        trainerSegments: {
          introductionTitle: "The next exam is:",
          introductionButtonStart: "Start with {{segmentTitle}}",
          introductionButtonBack: "Previous step",
          illustrationStatusSuccess: "Success",
          illustrationStatusKeepGoing: "Keep going",
          illustrationStatusWellDone: "Well done",
          textStatusFinished: "Finished",
          textStatusNext: "Next",
          buttonStatusNext: "To the explanation",
        },
        trainersOverview: {
          title: "Trainers",
          subtitle:
            "To better understand the material, we have prepared a number of practice modules for you!",
          start: "Start",
          trainerCard: {
            questions_one: "question",
            questions_other: "questions",
          },
        },
        activityCheckout: {
          finished: "Finished",
          whatIsNext: "What do you want to do next?",
          toDashboard: "To dashboard",
          startWith: "Start with {{activityTitle}}",
          startChapterTest: "Start chapter test",
          navigationOptionsTitle: "Or rather...",
        },
        chapterOverview: {
          progressProceedWithTitle: "theory",
          progressProceedWith: "Continuing where you left off:",
          remainInPageCta: "or browse all chapters",
          remainInPageCtaMobile: "all chapters",
          remainInPageCtaArrowAlt: "Reference to all chapters",
          partsLabel_one: "part",
          partsLabel_other: "parts",
        },
        chapterDetails: {
          progressProceedWithCta: "Proceed with:",
          allActivitiesDone: "All activities in this chapter are done.",
        },
        menu: {
          dashboard: "Dashboard",
          chapters: "Theory",
          trainers: "Trainers",
          logout: "Log out",
          practiceExam: "Practice exam",
        },
        header: {},
        status: {
          OPEN: "Not started yet",
          DOING: "Doing",
          DONE: "Done",
        },
        loading: "Loading ...",
        dashboardPage: "Dashboard",
        course: "Course",
        chapterPage: "Chapter",
        chapterTest: "Chapter test",
        section: "Section",
        activity: "Activity",
        page: "Page",
        toHomeCta: "Back to home",
        modal: {
          leavePage: {
            title: "You will leave the page",
            description: "Are you sure?",
            confirmButton: "Yes",
            cancelButton: "Cancel",
          },
          exitActivityTitle: "You will leave the lesson",
          exitActivityDescription: "Are you sure?",
          exitActivityConfirm: "Yes, stop lesson",
          exitActivityCancel: "No, stay here!",
          exitToDashboardActivityTitle: "You will return to the dashboard",
          exitToDashboardActivityDescription: "Are you sure?",
          exitToDashboardActivityConfirm: "Yes, leave the lesson and return to the dashboard",
          exitToDashboardActivityCancel: "Cancel",
          restartActivityTitle: "You will restart the lesson",
          restartActivityDescription: "Are you sure?",
          restartActivityConfirm: "Yes, restart the lesson",
          restartActivityCancel: "Cancel",
          exitChapterTestTitle: "You will leave the chapter test",
          exitChapterTestConfirm: "Yes, stop test",
          exitPractiseExamTitle: "You will leave the practise exam",
          exitPractiseExamConfirm: "Yes, stop exam",
          exitResultsTitle: "You will leave the results",
          exitResultsDescription: "Are you sure?",
        },
        onboarding: {
          closeOnboarding: "Close",
          nextSlide: "Next",
          finishOnboarding: "Get to work!",
        },
      },
    },
  },
});

export default i18n;
