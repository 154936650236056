const {
  REACT_APP_AUTH_AUTHORITY,
  REACT_APP_AUTH_CLIENT_ID,
  REACT_APP_AUTH_REDIRECT_URI,
  REACT_APP_AUTHORIZATION_ENDPOINT,
  REACT_APP_TOKEN_ENDPOINT,
  REACT_APP_SIGN_OUT_URL,
  REACT_APP_AUTH_LOG_OUT_URI,
} = process.env;

export const USER_KEY = "anwb-user";

export const AUTH_AUTHORITY = REACT_APP_AUTH_AUTHORITY || "";
export const AUTH_CLIENT_ID = REACT_APP_AUTH_CLIENT_ID || "";
export const AUTH_REDIRECT_URI = REACT_APP_AUTH_REDIRECT_URI || "";
export const AUTHORIZATION_ENDPOINT = REACT_APP_AUTHORIZATION_ENDPOINT;
export const TOKEN_ENDPOINT = REACT_APP_TOKEN_ENDPOINT;
export const SIGN_OUT_URL = REACT_APP_SIGN_OUT_URL;
export const AUTH_LOG_OUT_URI = REACT_APP_AUTH_LOG_OUT_URI ?? "https://www.anwbtheorie.nl";

export const OAUTH_KEY = `oidc.user:${AUTH_AUTHORITY}:${AUTH_CLIENT_ID}`;

export const TYPOGRAPHY_FONT_CLAN_PRO = `"Clan Pro", sans-serif`;
export const TYPOGRAPHY_FONT_CLANOT = `"ClanOT", Arial, sans-serif`;
export const TYPOGRAPHY_FONT_CLANPRO_COMPRESSED_MEDIUM = `"ClanPro Compressed Medium", Arial, sans-serif`;
export const TYPOGRAPHY_FONT_CLANPRO_COMPRESSED_BOLD = `"ClanPro Compressed Bold", Arial, sans-serif`;
export const TYPOGRAPHY_FONT_BERNINA_SANS = `"Bernina Sans", Arial, sans-serif`;

export const TYPOGRAPHY_COMPRESSED_BOLD = 700;
export const TYPOGRAPHY_REGULARSEMI_BOLD = 600;
export const TYPOGRAPHY_COMPRESSED_REGULAR = 500;
export const TYPOGRAPHY_NARROW_BOLD = 400;

export const TYPOGRAPHY_MEDIUM_FONT_SIZE = 2;
export const TYPOGRAPHY_SMALL_FONT_SIZE = 1.47;
export const TYPOGRAPHY_LARGE_FONT_SIZE = 2.4;
export const TYPOGRAPHY_MEDIUM_LINE_HEIGHT = 2.4;
export const TYPOGRAPHY_SMALL_LINE_HEIGHT = 1.5;
export const TYPOGRAPHY_SMALL_TITLE_LINE_HEIGHT = 1.87;
export const TYPOGRAPHY_SMALL_EMPHASIS_LINE_HEIGHT = 1.17;
