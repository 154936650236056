import { useMemo } from "react";

import useCourse from "@hooks/useCourse";

import { ContentType } from "@topgun/shared/src/types/frontend";

export default (activityId: string | undefined) => {
  const { course, ...courseData } = useCourse();

  const activityData = useMemo(() => {
    if (!course || !activityId) {
      return {};
    }

    let activity = undefined;
    let nextActivity = undefined;

    for (const node of course.filterBreadthFirst((item) => item?.type === ContentType.ACTIVITY)) {
      if (activity !== undefined) {
        nextActivity = node;
        break;
      }
      if (node.value.id === activityId) {
        activity = node;
      }
    }

    const chapterOfActivity = activity?.parent?.parent;
    const chapterOfNextActivity = nextActivity?.parent?.parent;

    return {
      activity,
      nextActivity,
      isLastActivityInChapter:
        nextActivity === undefined || chapterOfActivity !== chapterOfNextActivity,
      pages: activity?.children,
    };
  }, [course, activityId]);

  return {
    ...courseData,
    ...activityData,
  };
};
