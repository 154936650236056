import { useEffect, useRef, useState } from "react";

import type { Workbox } from "workbox-window";

import NewRelicHelper from "@topgun/shared/src/helper/NewRelicHelper";

const CHECK_UPDATE_INTERVAL = 60 * 60 * 1000; // ms

export default () => {
  const [registration, setRegistration] = useState<ServiceWorkerRegistration | undefined>(
    undefined,
  );
  const wb = useRef<Workbox | null>(null);

  useEffect(() => {
    let updateInterval: NodeJS.Timer | undefined;

    const controllingListener = () => {
      window.location.reload();
    };

    const register = async () => {
      if (!("serviceWorker" in navigator) || process.env.NODE_ENV === "development") {
        return;
      }
      try {
        const { Workbox } = await import("workbox-window");
        // eslint-disable-next-line camelcase
        wb.current = new Workbox(`${__webpack_public_path__}sw.js`, { updateViaCache: "none" });

        wb.current.addEventListener("controlling", controllingListener);

        setRegistration(await wb.current.register());

        updateInterval = setInterval(() => {
          // For long-lived SPA sessions without page reload, check periodically for SW updates
          void wb.current?.update();
        }, CHECK_UPDATE_INTERVAL);
      } catch (err) {
        NewRelicHelper.logError(err);
        // eslint-disable-next-line no-console
        console.error("Failed to register service worker", err);
      }
    };

    void register();

    return () => {
      if (controllingListener != null) {
        wb.current?.removeEventListener("controlling", controllingListener);
      }
      if (updateInterval != null) {
        clearInterval(updateInterval as NodeJS.Timeout);
      }
    };
  }, []);

  return { registration };
};
