import { customVariables } from "@theme/custom-variables";
import styled, { css } from "styled-components";

import {
  ButtonIcon,
  ButtonIconProps,
  ButtonPrimary,
  ButtonSecondary,
  ButtonTertiary,
} from "@anwb/button";
import { UiIconsKey } from "@anwb/icon-utils/dist/ui-icons";
import { DefaultThemeProps } from "@anwb/theme";

export const ArrowIcon: Record<"LEFT" | "RIGHT", UiIconsKey> = {
  LEFT: "arrow-left",
  RIGHT: "arrow-right",
};

const buttonPrimaryStyles = ({ theme }: DefaultThemeProps) => css`
  && {
    border-radius: 2rem;
    height: auto;
    padding-block: 0.5rem;
    min-height: 3.2rem;

    &:hover {
      color: ${customVariables.colors.textButtonInverted};
    }
    &:active,
    &:focus {
      color: ${theme.colors.buttons.textButtonPrimary};
    }
  }
`;

export const ButtonPrimaryDark = styled(ButtonPrimary).attrs({
  variant: "on-dark",
})`
  ${buttonPrimaryStyles};
`;

const buttonPrimaryIconRightStyles = () => css`
  && {
    position: relative;
    padding: 0 2.7rem;

    > span {
      position: initial;

      > span {
        position: absolute;
        right: 20px;
        top: 0;
        bottom: 0;
      }
    }
  }
`;

export const ButtonPrimaryDarkIconRight = styled(ButtonPrimary).attrs({
  variant: "on-dark",
})`
  ${buttonPrimaryStyles};
  ${buttonPrimaryIconRightStyles};
`;

const buttonSecondaryDarkStyles = () => css`
  && {
    height: auto;
    padding-block: 0.5rem;
    min-height: 3.2rem;
  }
`;

export const ButtonSecondaryDark = styled(ButtonSecondary)`
  ${buttonSecondaryDarkStyles}
  && {
    border-radius: 2rem;
  }
`;

const buttonTertiaryDarkStyles = ({ theme }: DefaultThemeProps) => css`
  && {
    border-radius: 2rem;
    height: auto;
    padding-block: 0.5rem;
    min-height: 3.2rem;

    :hover {
      background-color: ${customVariables.colors.bgTertiaryButtonHover};
      border-color: ${theme.colors.blanc.borderButton};
    }
  }
`;

export const ButtonTertiaryDark = styled(ButtonTertiary).attrs({
  variant: "on-dark",
})`
  ${buttonTertiaryDarkStyles}
  && {
    border-radius: 2rem;
  }
`;

const buttonTertiaryDarkIconLeftStyles = () => css`
  && {
    position: relative;

    > span {
      position: absolute;
      left: 20px;
    }
  }
`;

export const ButtonTertiaryDarkIconLeft = styled(ButtonTertiary).attrs({
  variant: "on-dark",
})`
  ${buttonTertiaryDarkStyles};
  ${buttonTertiaryDarkIconLeftStyles};
  && {
    border-radius: 2rem;
  }
`;

export const ButtonIconDark = styled(ButtonIcon).attrs({
  variant: "on-dark",
})``;

type NavigationButtonProps = DefaultThemeProps & { $variant?: "previous" | "next" };

const navigationButtonIconStyles = ({
  theme,
  icon,
}: NavigationButtonProps & ButtonIconProps) => css`
  width: 3rem;
  height: 3rem;
  border-radius: 1.5rem;
  background-color: ${theme.colors.buttons.bgButtonSecondaryActive};
  border: none;

  ${(icon === ArrowIcon.LEFT || icon === ArrowIcon.RIGHT) &&
  css`
    @media (hover: hover) {
      :hover {
        span {
          transform: translateX(${icon === ArrowIcon.LEFT ? "-0.2rem" : "0.2rem"});
          transition: transform 0.2s ease 0s;
        }
      }
    }
  `}
`;

export const NavigationButtonIcon = styled(ButtonIconDark).attrs<
  NavigationButtonProps,
  ButtonIconProps
>(({ $variant, icon }) => {
  return {
    color: "tertiary",
    icon: icon ?? ($variant === "previous" ? ArrowIcon.LEFT : ArrowIcon.RIGHT),
  };
})<NavigationButtonProps>`
  ${navigationButtonIconStyles};
`;

export const TransparentButtonIcon = styled(ButtonIcon).attrs({
  variant: "on-dark",
  color: "tertiary",
})`
  border: none;

  &:hover {
    background-color: transparent;
    border: none;
  }
`;

const secondaryNavigationButtonIconStyles = ({ theme }: NavigationButtonProps) => css`
  background-color: ${theme.colors.buttons.bgButtonSecondaryOnDark};
  color: ${theme.colors.buttons.textButtonTertiary};
  transition: 0.15s ease-in-out;

  &:not(:hover):focus {
    color: ${theme.colors.buttons.textButtonTertiary};
  }
`;

export const SecondaryNavigationButtonIcon = styled(NavigationButtonIcon).attrs({
  color: "secondary",
})<NavigationButtonProps>`
  ${secondaryNavigationButtonIconStyles};
`;

export const RedesignSecondaryNavigationButtonIcon = styled(NavigationButtonIcon).attrs(
  (props: NavigationButtonProps) => ({
    $variant: props.$variant,
  }),
)<NavigationButtonProps>`
  ${secondaryNavigationButtonIconStyles};
  ${(props) => props.$variant === "next" && "margin-left: auto;"}
`;
