import {
  TYPOGRAPHY_FONT_BERNINA_SANS,
  TYPOGRAPHY_FONT_CLANPRO_COMPRESSED_BOLD,
  TYPOGRAPHY_FONT_CLANPRO_COMPRESSED_MEDIUM,
  TYPOGRAPHY_FONT_CLAN_PRO,
} from "@utils/constants";
import { css } from "styled-components";

export const HeadingNarrowMarketingRegularStyles = () => css`
  font-family: ${TYPOGRAPHY_FONT_CLAN_PRO};
  font-weight: 400;
  font-size: 3.2rem;
  line-height: 4rem;
`;

export const HeadingNarrowMarketingBoldStyles = () => css`
  font-family: ${TYPOGRAPHY_FONT_CLAN_PRO};
  font-weight: 700;
  font-size: 3.2rem;
  line-height: 4rem;
`;

export const HeadingNarrowBoldCapsStyles = () => css`
  font-family: ${TYPOGRAPHY_FONT_CLAN_PRO};
  font-weight: 700;
  font-size: 2.4rem;
  line-height: 1.86667rem;
  text-transform: uppercase;
`;

export const HeadingCompressedBoldXLStyles = () => css`
  font-family: ${TYPOGRAPHY_FONT_CLANPRO_COMPRESSED_BOLD};
  font-weight: 700;
  font-size: 4.26667rem;
  line-height: 5.33333rem;
`;

export const HeadingCompressedBoldLStyles = () => css`
  font-family: ${TYPOGRAPHY_FONT_CLANPRO_COMPRESSED_BOLD};
  font-weight: 700;
  font-size: 3.2rem;
  line-height: 3.2rem;
`;

export const HeadingCompressedBoldMStyles = () => css`
  font-family: ${TYPOGRAPHY_FONT_CLANPRO_COMPRESSED_BOLD};
  font-weight: 700;
  font-size: 2.4rem;
  line-height: 2.66667rem;
`;

export const HeadingCompressedBoldSStyles = () => css`
  font-family: ${TYPOGRAPHY_FONT_CLANPRO_COMPRESSED_BOLD};
  font-weight: 700;
  font-size: 1.86667rem;
  line-height: 2.13333rem;
`;

export const HeadingCompressedBoldXSStyles = () => css`
  font-family: ${TYPOGRAPHY_FONT_CLANPRO_COMPRESSED_BOLD};
  font-weight: 700;
  font-size: 1.06667rem;
  line-height: 1.33333rem;
`;

export const SubheadingMStyles = () => css`
  font-family: ${TYPOGRAPHY_FONT_CLANPRO_COMPRESSED_MEDIUM};
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.73333rem;
`;

export const SubheadingLStyles = () => css`
  font-family: ${TYPOGRAPHY_FONT_CLANPRO_COMPRESSED_MEDIUM};
  font-weight: 500;
  font-size: 3.2rem;
  line-height: 2.66667rem;
`;

export const SubheadingSStyles = () => css`
  font-family: ${TYPOGRAPHY_FONT_CLANPRO_COMPRESSED_MEDIUM};
  font-weight: 500;
  font-size: 1.06667rem;
  line-height: 1.33333rem;
`;

export const BodyLStyles = () => css`
  font-family: ${TYPOGRAPHY_FONT_BERNINA_SANS};
  font-weight: 400;
  font-size: 1.1rem;
  line-height: 1.46667rem;
`;

export const BodyMStyles = () => css`
  font-family: ${TYPOGRAPHY_FONT_BERNINA_SANS};
  font-weight: 400;
  font-size: 1.06667rem;
  line-height: 1.46667rem;
`;

export const BodyBoldLStyles = () => css`
  font-family: ${TYPOGRAPHY_FONT_BERNINA_SANS};
  font-weight: 600;
  font-size: 1.1rem;
  line-height: 1.46667rem;
`;

export const BodySStyles = () => css`
  font-family: ${TYPOGRAPHY_FONT_BERNINA_SANS};
  font-weight: 400;
  font-size: 0.93333rem;
  line-height: 1.26667rem;
`;

export const InteractionLabelStyles = () => css`
  font-family: ${TYPOGRAPHY_FONT_CLAN_PRO};
  font-weight: 500;
  font-size: 1.06667rem;
`;
