import { ContentItem, INotificationStructure, MaterialType, VariantType } from "@domain/types";
import { ContentSequenceHelper } from "@helpers/ContentSequenceHelper";
import { ContentStatusHelper } from "@helpers/ContentStatusHelper";
import { Node } from "@utils/tree/Node";
import { t } from "i18next";

import { ContentType, IContent } from "@topgun/shared/src/types/frontend";

export function getNotificationStructure(
  type: MaterialType,
  variant: VariantType = "error",
): INotificationStructure {
  const materialType = t(`notifications.type.${type.toLowerCase()}`);
  return {
    title: t("notifications.error.title", {
      materialType,
    }),
    variant,
    children: t("notifications.error.text", {
      materialType,
    }),
  };
}

export function filterInvalidType(content: IContent[]): IContent[] {
  return content.filter(function hasValidType(item) {
    if (!item.type || (item.type === ContentType.PAGE && !item.data?.contentId)) {
      return false;
    }
    if (item.children) {
      // eslint-disable-next-line no-param-reassign
      item.children = item.children.filter(hasValidType);
    }

    return true;
  });
}

export function constructContentTree(
  data: IContent[],
  progress: string[],
): Node<ContentItem | null> {
  function create(contentItem: IContent): Node<ContentItem> {
    const node = new Node<ContentItem>({
      id: contentItem.id,
      title: contentItem.title,
      type: contentItem.type,
      status: undefined,
      contentId: contentItem.data?.contentId,
      image: contentItem.image,
      summary: contentItem.summary,
    });
    (contentItem.children || []).forEach((child) => {
      node.addChild(create(child));
    });
    return node;
  }

  const root = new Node<ContentItem | null>(null);
  data
    .map((contentItem) => create(contentItem))
    .forEach((contentItemNode) => root.addChild(contentItemNode));

  ContentStatusHelper.setStatusByFinishedContent(root, progress);
  ContentSequenceHelper.setSequenceLabels(root);

  return root;
}
