import { BodySStyles } from "@components/typography/shared/Typography.styled";
import styled from "styled-components";

import Typography, { TypographyBaseProps } from "@anwb/typography";

export const BodyS = styled(Typography)<TypographyBaseProps>`
  ${BodySStyles};
`;

export default BodyS;
