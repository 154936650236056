import { useClient } from "@components/ClientProvider";
import { IConfiguration } from "@domain/types";
import { getConfigurationQueryKey } from "@hooks/utils";
import { useQuery } from "@tanstack/react-query";
import { useAuth } from "oidc-react";

export default (
  configurationId: string,
): {
  isLoading: boolean;
  error: unknown;
  dynamicActivityConfiguration: IConfiguration | undefined;
} => {
  const { userData } = useAuth();
  const { dataClient } = useClient();
  const { access_token: accessToken } = userData || {};

  const { isLoading, error, data } = useQuery({
    queryKey: getConfigurationQueryKey(configurationId, accessToken),
    enabled: !!accessToken,
    queryFn: async ({ signal }) => {
      if (!accessToken) {
        throw new Error("Missing access token");
      }
      return dataClient.getConfiguration(configurationId, signal, accessToken);
    },
  });

  return {
    isLoading,
    error,
    dynamicActivityConfiguration: data,
  };
};
