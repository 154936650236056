import { FunctionComponent, useEffect } from "react";
import { Outlet } from "react-router-dom";

import Header from "@components/Header";
import { useHeaderUpdater } from "@components/HeaderContextProvider";

import { useApplicationSize } from "@anwb/providers-application";

import { HeaderLayoutWrapper } from "./styles";

const HeaderLayout: FunctionComponent = () => {
  const { setShowHeaderLogo } = useHeaderUpdater();

  const applicationSize = useApplicationSize();
  useEffect(() => {
    setShowHeaderLogo(applicationSize !== "small");
  }, [setShowHeaderLogo, applicationSize]);

  useEffect(
    () => () => {
      setShowHeaderLogo(true);
    },
    [setShowHeaderLogo],
  );

  return (
    <HeaderLayoutWrapper data-testid="protected-layout">
      <Header />
      <Outlet />
    </HeaderLayoutWrapper>
  );
};

export default HeaderLayout;
