import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import {
  BackToDashboardButton,
  BorderWrapper,
  ButtonSeparator,
  ButtonWrapper,
  ChapterTestWrapper,
  NextActivityButton,
  NextActivityCardWrapper,
  StyledTitle,
  TitleAboveNavigationButtons,
} from "@components/Activity/ActivityCheckOut/NextActivityCard/styles";
import TrainerCard from "@components/TrainerCard";
import { IConfiguration } from "@domain/types";

interface Props {
  title: string | undefined;
  id: string | undefined;
  chapterTest: IConfiguration | undefined;
}

const NextActivityCard: FunctionComponent<Props> = ({ title, id, chapterTest }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const navigateToDashboard = () => {
    navigate("/");
  };

  const handleGoToActivity = (activityId: string) => {
    navigate(`/activities/${activityId}`);
  };

  return (
    <NextActivityCardWrapper data-testid="next-activity-card">
      <StyledTitle>{t("activityCheckout.whatIsNext")}</StyledTitle>
      {chapterTest && (
        <>
          <ChapterTestWrapper data-testid="chapter-test-card">
            <TrainerCard
              wide
              high={false}
              trainer={chapterTest}
              withButton
              title={t("activityCheckout.startChapterTest")}
            />
          </ChapterTestWrapper>

          <BorderWrapper>
            <ButtonSeparator />
            <TitleAboveNavigationButtons>
              {t("activityCheckout.navigationOptionsTitle")}
            </TitleAboveNavigationButtons>
            <ButtonSeparator />
          </BorderWrapper>
        </>
      )}
      <ButtonWrapper>
        <BackToDashboardButton onClick={navigateToDashboard}>
          {t("activityCheckout.toDashboard")}
        </BackToDashboardButton>
        {title && id && (
          <NextActivityButton
            dataTest="next-activity-button"
            onClick={() => handleGoToActivity(id)}
          >
            {t("activityCheckout.startWith", { activityTitle: title })}
          </NextActivityButton>
        )}
      </ButtonWrapper>
    </NextActivityCardWrapper>
  );
};

export default NextActivityCard;
