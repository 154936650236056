import { useState } from "react";
import { Outlet } from "react-router-dom";

import { onBeforeSignIn } from "@components/AuthProviderWithConfig";
import CenteredSpinner from "@components/CenteredSpinner";
import { useAuth } from "oidc-react";

function ProtectedLayout() {
  const { userManager } = useAuth();
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  userManager.getUser().then((loggedInUser) => {
    if (!loggedInUser) {
      const state = onBeforeSignIn();
      userManager
        .signinRedirect({ state })
        .then(() => {
          console.debug("Redirecting to sign in page");
        })
        .catch((reason) => {
          throw reason;
        });
    } else {
      setIsLoggedIn(true);
    }
  });
  return (
    <div data-testid="protected-layout">
      {isLoggedIn ? <Outlet /> : <CenteredSpinner size="large" />}
    </div>
  );
}

export default ProtectedLayout;
