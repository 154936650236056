import { useAuth } from "oidc-react";

export type User = {
  id: string;
  accessToken: string;
};

export function useUser(): User | null {
  const { userData } = useAuth();
  const { profile, access_token: accessToken } = userData || {};

  if (!profile || !accessToken) {
    return null;
  }

  return {
    id: profile.sub,
    accessToken: accessToken,
  };
}
