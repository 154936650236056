/* eslint-disable no-underscore-dangle */

export class Node<T> {
  private readonly _children: Node<T>[];

  private _parent: WeakRef<Node<T>> | undefined;

  constructor(public readonly value: T) {
    this._children = [];
  }

  public addChild(child: Node<T>): this {
    this.children.push(child);
    // eslint-disable-next-line no-param-reassign
    child.parent = this;
    return this;
  }

  get children(): Node<T>[] {
    return this._children;
  }

  hasParent(): this is { parent: Node<T> } {
    return this._parent?.deref() !== undefined;
  }

  get parent(): Node<T> | undefined {
    return this._parent?.deref();
  }

  set parent(value: Node<T> | undefined) {
    this._parent = value !== undefined ? new WeakRef(value) : undefined;
  }

  hasValue(): this is Node<NonNullable<T>> {
    return this.value !== undefined && this.value !== null;
  }

  /*
   Traverses for every node, first its children and then itself.
   */
  public *depthFirstPostOrderTraversal(root: Node<T> = this): Generator<Node<T>> {
    for (const child of root.children) {
      yield* this.depthFirstPostOrderTraversal(child);
    }
    yield root;
  }

  public *breadthFirstTraversal(root: Node<T> = this): Generator<Node<T>> {
    const queue = [root];
    while (queue.length > 0) {
      const current = queue.shift();
      if (current === undefined) {
        continue;
      }
      yield current;
      queue.push(...current.children);
    }
  }

  public *filterBreadthFirst(predicate: (value: T) => boolean): Generator<Node<T>> {
    for (const node of this.breadthFirstTraversal()) {
      const { value } = node;
      if (predicate(value)) {
        yield node;
      }
    }
  }
}
