import { useClient } from "@components/ClientProvider";
import { DynamicActivity } from "@domain/types";
import { useMutation } from "@tanstack/react-query";
import { UseMutateFunction } from "@tanstack/react-query/src/types";
import { useAuth } from "oidc-react";

import NewRelicHelper from "@topgun/shared/src/helper/NewRelicHelper";

export default (
  configurationId: string,
): {
  isLoading: boolean;
  error: unknown;
  createDynamicActivity: UseMutateFunction<DynamicActivity>;
} => {
  const { userData } = useAuth();
  const { dataClient } = useClient();
  const { access_token: accessToken } = userData || {};

  const { isLoading, error, mutate } = useMutation({
    mutationFn: () => {
      if (!accessToken) {
        throw new Error("Missing access token");
      }
      return dataClient.createDynamicActivity(configurationId, accessToken);
    },
    onError: (err) => {
      NewRelicHelper.logError(err);
    },
  });

  return { isLoading, error, createDynamicActivity: mutate };
};
