import {
  OnBoardingVideo,
  OnBoardingVideoContainer,
  OnBoardingVideoSlide,
  OnBoardingVideoTitle,
} from "@components/Onboarding/styles/onboardingSlide.styled";
import { OnBoardingVideoType } from "@domain/types";

const VideoSlide = ({ content }: { content: OnBoardingVideoType }) => {
  return (
    <OnBoardingVideoSlide>
      <OnBoardingVideoContainer>
        <OnBoardingVideo muted loop autoPlay>
          <source src={content.video} type="video/mp4" />
          Your browser does not support HTML video.
        </OnBoardingVideo>
        <OnBoardingVideoTitle tagName="h4">{content.title}</OnBoardingVideoTitle>
      </OnBoardingVideoContainer>
    </OnBoardingVideoSlide>
  );
};

export default VideoSlide;
