import useCourse from "@hooks/useCourse";

export const useCourseWithCTA = () => {
  const coursesWithCTA = process.env.REACT_APP_COURSEID_WITH_CTA?.split(",");
  const { course } = useCourse();

  if (course === undefined || coursesWithCTA === undefined) {
    return false;
  }

  const courseId = course.value.id;

  return coursesWithCTA.includes(courseId);
};
