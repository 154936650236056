import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import ChapterNumberTag from "@components/ChapterNumberTag";
import ChapterProgressBar from "@components/ChapterProgressBar";
import { ChapterImageHeaderTitle } from "@components/CurrentChapterCard/styles";
import {
  DashboardContainer,
  DashboardGreeting,
  DashboardGridHeader,
  DashboardSubTitle,
  DashboardTitleHeader,
  Name,
} from "@components/Dashboard/styles";
import {
  FullscreenChapterBlock,
  FullscreenChapterHeader,
  FullscreenChapterImage,
  FullscreenChapterProgressBarWrapper,
} from "@components/FullscreenChapterCard/styles/fullscreenChapterCard.styled";
import { PageResponse } from "@domain/types";
import { getUser } from "@helpers/getUser";
import { useAuth } from "oidc-react";

type FullscreenChapterCardProps = {
  recentAccessedPage: PageResponse;
};

const FullscreenChapterCard: FunctionComponent<FullscreenChapterCardProps> = ({
  recentAccessedPage,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const auth = useAuth();
  const userName = getUser(auth)?.name;

  const handleGoToChapter = (chapterId: string) => {
    navigate(`/chapters/${chapterId}`);
  };

  const {
    id: chapterId,
    title: chapterTitle,
    summary: chapterSummary,
    sequenceLabel,
    image,
  } = recentAccessedPage.chapter.value;

  return (
    <FullscreenChapterImage image={image}>
      <DashboardContainer>
        <DashboardGridHeader>
          <DashboardTitleHeader>
            <DashboardGreeting>{t("dashboard.theory.greeting")}</DashboardGreeting>
            {userName && <Name>{userName},</Name>}
            <DashboardSubTitle>{t("dashboard.theory.subtitle")}</DashboardSubTitle>
          </DashboardTitleHeader>
        </DashboardGridHeader>
        <FullscreenChapterBlock
          onClick={() => handleGoToChapter(chapterId)}
          data-testid="current-chapter-card"
        >
          <FullscreenChapterHeader>
            {sequenceLabel && <ChapterNumberTag chapterNumber={sequenceLabel} />}
            <ChapterImageHeaderTitle tagName="h3">{chapterTitle}</ChapterImageHeaderTitle>
          </FullscreenChapterHeader>
          <p>{chapterSummary}</p>
          <FullscreenChapterProgressBarWrapper>
            <ChapterProgressBar
              remainedWithLabel={t("dashboard.theory.progressProceedWithCta")}
              recentAccessedPage={recentAccessedPage}
            />
          </FullscreenChapterProgressBarWrapper>
        </FullscreenChapterBlock>
      </DashboardContainer>
    </FullscreenChapterImage>
  );
};

export default FullscreenChapterCard;
