import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useHeaderUpdater } from "@components/HeaderContextProvider";
import { MarkdownSanitized } from "@components/MarkdownSanitized/MarkdownSanititzed";
import Notification from "@components/Notification";
import {
  ButtonWrapper,
  ImageBackgroundWrapper,
  ImageContentWrapper,
  ReactPlayerElement,
  StartButton,
  TextContentContainer,
  TitleTrainer,
  TotalAmount,
  VideoGridItem,
} from "@components/Trainer/ImageHeader/styles";
import BodyL from "@components/typography/BodyL";

import Grid from "@anwb/grid";

import useResponsiveBynderVideo from "@topgun/shared/src/hooks/useResponsiveBynderVideo";
import { AuthorImageProps } from "@topgun/shared/src/types/frontend";

interface Props {
  totalAmount: number;
  title: string;
  introductionText: string;
  onButtonClick: () => void;
  backgroundImage: AuthorImageProps;
  video?: string;
}

const ImageHeader: FunctionComponent<Props> = ({
  totalAmount,
  title,
  introductionText,
  onButtonClick,
  backgroundImage,
  video,
}) => {
  const { setShowHeaderMenu, clearNavigateBack } = useHeaderUpdater();
  const videoUrl = useResponsiveBynderVideo(video);
  const [showError, setShowError] = useState(false);

  const { t } = useTranslation();

  useEffect(
    () => () => {
      clearNavigateBack();
      setShowHeaderMenu(true);
    },
    [clearNavigateBack, setShowHeaderMenu],
  );

  return (
    <>
      <ImageBackgroundWrapper image={backgroundImage} />
      <ImageContentWrapper>
        <TitleTrainer>{title}</TitleTrainer>
        <Grid constrainedWidth columns={{ sm: 1, md: 1, lg: 2 }}>
          <Grid.Item>
            <TextContentContainer>
              <TotalAmount>
                <strong>
                  {totalAmount}{" "}
                  {t("trainersOverview.trainerCard.questions", { count: totalAmount })}
                </strong>
              </TotalAmount>
              <MarkdownSanitized markdown={introductionText} container={BodyL} />
              <ButtonWrapper>
                <StartButton onClick={() => onButtonClick()}>
                  {t("trainersOverview.start")}
                </StartButton>
              </ButtonWrapper>
            </TextContentContainer>
          </Grid.Item>
          {videoUrl && (
            <VideoGridItem>
              {showError ? (
                <Notification variant="warning" title={t("notifications.videoError.title")}>
                  {t("notifications.videoError.text")}
                </Notification>
              ) : (
                <ReactPlayerElement
                  pip={false}
                  url={videoUrl}
                  controls
                  width="100%"
                  height="100%"
                  onError={() => setShowError(true)}
                  config={{
                    file: {
                      attributes: {
                        controlsList: "nodownload",
                        disablePictureInPicture: true,
                      },
                    },
                  }}
                />
              )}
            </VideoGridItem>
          )}
        </Grid>
      </ImageContentWrapper>
    </>
  );
};

export default ImageHeader;
