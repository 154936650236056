import { FunctionComponent, MouseEvent, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHref, useNavigate } from "react-router-dom";

import ErrorNotification from "@components/ErrorNotification";
import { useHeaderUpdater } from "@components/HeaderContextProvider";
import { MaterialType } from "@domain/types";
import useChapters from "@hooks/useChapters";
import useCourse from "@hooks/useCourse";
import useDynamicActivityConfigurations from "@hooks/useDynamicActivityConfigurations";
import { AUTH_CLIENT_ID } from "@utils/constants";

import { IconVariantKey } from "@anwb/icon-utils";
import List from "@anwb/list";
import { Popover } from "@anwb/popover";

import { DynamicActivityConfigurationType } from "@topgun/shared/src/types/frontend";

import {
  HeaderMenuBody,
  HeaderMenuFooter,
  HeaderMenuFooterButton,
  HeaderMenuIconButton,
  HeaderMenuLabel,
  HeaderMenuListLabel,
  HeaderMenuRight,
  HeaderMenuTrigger,
  HeaderMenuWrapper,
} from "./styles";

type MenuOption = {
  label: string;
  href?: string;
  openInNewTab?: boolean;
};

const HeaderMenu: FunctionComponent = () => {
  const [open, setOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<MenuOption | undefined>();
  const { setShowOnBoarding } = useHeaderUpdater();
  const navigate = useNavigate();
  const locationHref = useHref(window.location.href);
  const { t } = useTranslation();

  const { course } = useCourse({ useErrorBoundary: false });
  const { chapters } = useChapters();
  const {
    error,
    isLoading,
    dynamicActivityConfigurations: [{ id: examId } = { id: "" }] = [],
  } = useDynamicActivityConfigurations(
    [DynamicActivityConfigurationType.PRACTICE_EXAM],
    course?.value.id,
    { useErrorBoundary: false },
  );
  const hasChapters = chapters && chapters.length > 0;

  const options: MenuOption[] = useMemo(() => {
    const items = [
      {
        label: t("menu.dashboard"),
        href: "/dashboard",
      },
    ] as MenuOption[];

    if (hasChapters) {
      items.push({
        label: t("menu.chapters"),
        href: "/chapters",
      });
    }

    items.push({
      label: t("menu.trainers"),
      href: "/trainers",
    });

    if (!isLoading && examId) {
      items.push({
        label: t("menu.practiceExam"),
        href: `/trainers/${examId}`,
      });
    }

    items.push({
      label: t("menu.logout"),
      href: "/sign-out",
    });

    return items;
  }, [t, isLoading, examId, hasChapters]);

  const socialMedia = [
    {
      name: "instagram",
      url: "https://www.instagram.com/anwb/",
    },
    {
      name: "twitter",
      url: "https://twitter.com/ANWBverkeer",
    },
    {
      name: "facebook",
      url: "https://nl-nl.facebook.com/ANWB/",
    },
    {
      name: "youtube",
      url: "https://www.youtube.com/ANWB",
    },
  ];

  useEffect(() => {
    if (!locationHref) {
      return;
    }
    const foundOption = options.find(
      (option) => !!option.href && locationHref.includes(option.href),
    );

    setSelectedOption(foundOption || options[0]);
  }, [locationHref, setSelectedOption, options]);

  const handleOnChange = (href: string, openInNewTab = false) => {
    setOpen(false);

    if (openInNewTab) {
      window.open(href, "_blank", "noreferrer");
    } else {
      navigate(href);
    }
  };

  const handleOpenDashboard = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    navigate("dashboard");
  };

  return (
    <HeaderMenuWrapper data-testid="headerMenu">
      <Popover
        onClose={() => {
          setOpen(false);
        }}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
      >
        <Popover.Trigger>
          <HeaderMenuTrigger open={open} onClick={() => setOpen(!open)}>
            {open && <HeaderMenuIconButton data-testid="menu-close-button" icon="cross" />}
            {!open && <HeaderMenuIconButton data-testid="menu-open-button" icon="menu" />}
            <HeaderMenuLabel>{selectedOption?.label ? selectedOption.label : ""}</HeaderMenuLabel>
            <HeaderMenuRight>
              <HeaderMenuIconButton
                icon="home"
                data-testid="menu-home-button"
                onClick={(event: MouseEvent<HTMLElement>) => handleOpenDashboard(event)}
              />
            </HeaderMenuRight>
          </HeaderMenuTrigger>
        </Popover.Trigger>

        <Popover.Body>
          <HeaderMenuBody data-testid="header-menu-body">
            <List>
              {options &&
                options.map(({ label, href, openInNewTab }) => (
                  <List.Item key={`menu-item-${label}`}>
                    {href && (
                      <HeaderMenuListLabel onClick={() => handleOnChange(href, openInNewTab)}>
                        {label}
                      </HeaderMenuListLabel>
                    )}
                  </List.Item>
                ))}
            </List>
            {error && <ErrorNotification type={MaterialType.TRAINERS} />}
          </HeaderMenuBody>
        </Popover.Body>
        <Popover.Footer>
          <HeaderMenuFooter>
            {socialMedia.map(({ name, url }) => (
              <HeaderMenuFooterButton
                key={`social-${name}`}
                /* @ts-expect-error: type 'icon' does not match due to being a literal string */
                icon={`social-${name}` as IconVariantKey}
                onClick={() => handleOnChange(url, true)}
              />
            ))}
            {(AUTH_CLIENT_ID === "dev" || AUTH_CLIENT_ID === "test") && (
              <HeaderMenuFooterButton
                icon="information"
                onClick={() => {
                  setShowOnBoarding(true);
                }}
              />
            )}
          </HeaderMenuFooter>
        </Popover.Footer>
      </Popover>
    </HeaderMenuWrapper>
  );
};

export default HeaderMenu;
