import merge from "lodash.merge";

import { ANWBBrandRefresh, Theme } from "@anwb/theme";

import { ANWBDarkBrandRefreshThemePartial } from "./dark-anwb-brand-refresh-theme";

export const ANWBDarkBrandRefresh: Theme = {
  ...merge({}, ANWBBrandRefresh, ANWBDarkBrandRefreshThemePartial),
  name: "ANWB dark brand refresh",
};
