import { useMemo } from "react";

import { ContentItem } from "@domain/types";
import { ContentStatusHelper } from "@helpers/ContentStatusHelper";
import useContent from "@hooks/useContent";
import { Node } from "@utils/tree/Node";

import { ContentType } from "@topgun/shared/src/types/frontend";

export default (options?: { useErrorBoundary: boolean }) => {
  const { content, ...contentData } = useContent(options);

  const courseData = useMemo(() => {
    if (!content) {
      return {};
    }

    const courses = content.filterBreadthFirst((item) => item?.type === ContentType.COURSE);

    let course: Node<ContentItem> | undefined;

    for (const node of courses) {
      if (!node.hasValue()) {
        continue;
      }
      course = node;
      break;
    }

    const progress = course ? ContentStatusHelper.getActivityProgressPercentage(course) : undefined;
    return { course, progress };
  }, [content]);

  return {
    ...contentData,
    ...courseData,
  };
};
