import BodyL from "@components/typography/BodyL";
import { TYPOGRAPHY_REGULARSEMI_BOLD } from "@utils/constants";
import styled from "styled-components";

export const BodyLargeEmphasis = styled(BodyL)`
  && {
    font-weight: ${TYPOGRAPHY_REGULARSEMI_BOLD};
  }
`;
export default BodyLargeEmphasis;
