import React from "react";

import {
  Card,
  Disclaimer,
  MaxErrors,
  Score,
  Segment,
  SegmentInfo,
  Statement,
  Title,
} from "@components/Trainer/TrainerResult/styles/ScoreCard.styled";
import { DynamicActivitySegment, SegmentResult } from "@domain/types";

interface ScoreCardProperties {
  segments: DynamicActivitySegment[];
  segmentResults: SegmentResult[];
}

const ScoreCard = ({ segmentResults, segments }: ScoreCardProperties) => {
  const allSegmentsSuccessful = segmentResults.every((segment) => segment.success);

  return (
    <Card tagName="div">
      {segmentResults.map((segment, index) => (
        <Segment tagName="div" key={index}>
          <SegmentInfo>
            <Title>{segments[index].title}</Title>
            <MaxErrors tagName="div">
              Maximaal {Math.max(0, segment.amountTotal - segments[index].passMark)} fouten
            </MaxErrors>
          </SegmentInfo>
          <Score success={segment.success}>
            {segment.amountSuccess} <span>/ {segment.amountTotal}</span>
          </Score>
        </Segment>
      ))}
      <Statement success={allSegmentsSuccessful}>
        Dit betekent dat je het examen{" "}
        <span className={"yes-no"}>{allSegmentsSuccessful ? "wel" : "niet"}</span> gehaald zou
        hebben als het een echt CBR-examen was.
        <Disclaimer>Maar dat zegt niet alles: In elk examen krijg je andere vragen.</Disclaimer>
      </Statement>
    </Card>
  );
};

export default ScoreCard;
