import type { IH5PInstance as BaseH5pInstance } from "@lumieducation/h5p-webcomponents";
import type { To } from "@remix-run/router";
import { Node } from "@utils/tree/Node";
import { IdTokenClaims } from "oidc-client-ts";

import { UiIconsKey } from "@anwb/icon-utils";

import { AuthorImageProps, ContentType, IContent } from "@topgun/shared/src/types/frontend";

export interface IImageProps {
  image: AuthorImageProps;
}

export interface PageResponse {
  course: Node<ContentItem>;
  chapter: Node<ContentItem>;
  section: Node<ContentItem>;
  activity: Node<ContentItem>;
  page: Node<ContentItem>;
}

// Other
export type Material = {
  id: string;
  title: string;
  image?: AuthorImageProps;
  sequence?: number;
};

export type IQuestion = {
  id: string;
  title: string;
  sequence: number;
  visible: boolean;
  contentId: string;
  segment_id: string;
};

export type ISegment = {
  id: string;
  title: string;
  content?: string;
  passMark: number;
  sequence: number;
};

export enum Status {
  OPEN = "OPEN",
  DOING = "DOING",
  DONE = "DONE",
}

export enum MaterialType {
  COURSES = "courses",
  COURSE = "course",
  CHAPTER = "chapter",
  SECTION = "section",
  ACTIVITY = "activity",
  PAGE = "page",
  USER = "user",
  TRAINERS = "trainers",
}

export enum DialogTextFor {
  RESTART = "restart",
  DASHBOARD = "dashboard",
}

export interface ISource {
  sources: string[];
  amount: number;
}

export interface IConfiguration {
  id: string;
  type: string;
  title: string;
  subtitle: string;
  introductionText: string;
  video: string;
  belongsTo: string;
  image: AuthorImageProps;
  pages: IQuestion[];
  totalAmount: number;
  sources: ISource[];
  segments?: ISegment[];
}

export interface DynamicActivitySegment {
  id: string;
  title: string;
  passMark: number;
}

export interface DynamicActivity {
  id: string;
  type: string;
  title: string;
  subtitle: string;
  introductionText: string;
  image: AuthorImageProps;
  video: string;
  pages: IQuestion[];
  segments: DynamicActivitySegment[];
}

export interface SegmentResult {
  amountTotal: number;
  amountSuccess: number;
  success: boolean;
}

export interface IResult {
  answers: IContent[];
  segments: DynamicActivitySegment[];
  segmentResults: SegmentResult[];
  amountTotal: number;
  amountSuccess: number;
}

export type NavigateBackProps = {
  to: To;
  icon?: UiIconsKey;
};

export type ConfirmPageLeaveOptions = {
  title: string;
  description?: string;
  confirmButton?: string;
  cancelButton?: string;
};

export type BreadCrumb = {
  label: string;
  to: To;
  dialogTextFor?: DialogTextFor;
  disabled?: boolean;
};

export type VariantType = "error" | "info" | "success" | "warning";

export interface INotificationStructure {
  title: string;
  variant: VariantType;
  children: string;
}

export interface UserProfile extends IdTokenClaims {
  name?: string;
}

export interface H5pInstance extends BaseH5pInstance {
  metadata?: object;
}

export type ContentItem = {
  id: string;
  title: string;
  type: ContentType;
  status: Status | undefined;
  contentId: string | undefined;
  sequenceLabel?: string;
  image?: AuthorImageProps;
  summary?: string;
};

export enum OnBoardingType {
  VIDEO = "VIDEO",
  CONTENT = "CONTENT",
}

export type OnBoardingSlideType = {
  type: OnBoardingType;
  title: string;
};

export type OnBoardingVideoType = OnBoardingSlideType & {
  video: string;
};

export type OnBoardingContentType = OnBoardingSlideType & {
  text: string;
  list?: string[];
  image: string;
  isLastSlide: boolean;
};
