export const customVariables = {
  colors: {
    primaryBackground: "#000f22",
    textButtonInverted: "#01142c",
    primarySun: "#FFCD00",
    primaryBlue: `#0096DA`,
    uiSuccess: "#40A261",
    uiError: "#D1413B",
    trainerCard: "#1D2730",
    trainerCardHover: "#373E48;",
    ctaContentColor: "#fff",
    ctaBlockGradientPurple: "#9747ff",
    ctaBlockGradientBlue: "#45cbe9",
    bgTertiaryButtonHover: "rgba(255, 255, 255, 0.1)",
    errorDetailsText: "#606060",
    navigationDots: "rgba(255, 255, 255, 0.5)",
    modalOverlay: "rgba(14, 26, 37, 0.85)",
    segments: {
      bgColor: `#001B3C`,
      borderColor: `#00569D`,
    },
  },
};
