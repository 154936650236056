import { FunctionComponent, ReactElement, useEffect, useState } from "react";

import ErrorBoundaryFallback from "@components/ErrorBoundaryFallback";
import { OAUTH_KEY } from "@utils/constants";

// Fatal error messages of 'oidc-client-ts'
const AUTH_ERRORS = [
  "No state in response",
  "No matching state found in storage",
  "A code is required",
  "A refresh_token is required",
  "State does not match",
  "authority mismatch on settings vs. signin state",
  "client_id mismatch on settings vs. signin state",
  "Expected code in response",
  "ID Token is missing a subject claim",
  "sub in id_token does not match current sub",
  "invalid response_type in state",
];

interface Props {
  children: ReactElement;
}

const forceSignOut = () => {
  sessionStorage.removeItem(OAUTH_KEY);
};

/**
 * OIDC-client-ts does not have any support for error handling. Instead, it
 * throws an error which causes an unhandled rejection.
 *
 * This component 'catches' those and may show an error message for rejections
 * of oidc-client-ts package (based on error message).
 */
const AuthenticationErrorBoundary: FunctionComponent<Props> = ({ children }) => {
  const [error, setError] = useState<Error>();

  useEffect(() => {
    const promiseRejectionHandler = (event: PromiseRejectionEvent) => {
      if (event.reason instanceof Error) {
        const rejectionError = event.reason;
        if (AUTH_ERRORS.includes(rejectionError.message)) {
          rejectionError.name = "authError";
          setError(rejectionError);

          forceSignOut();
        }
      }
    };
    window.addEventListener("unhandledrejection", promiseRejectionHandler);

    return () => {
      window.removeEventListener("unhandledrejection", promiseRejectionHandler);
    };
  }, []);

  if (error) {
    return <ErrorBoundaryFallback error={error} showHeader={false} errorType="genericError" />;
  }
  return children;
};

export default AuthenticationErrorBoundary;
