import { FunctionComponent } from "react";

import Notification from "@components/Notification";
import { MaterialType } from "@domain/types";
import { getNotificationStructure } from "@utils/helpers";

type ErrorNotificationProps = {
  type: MaterialType;
};

const ErrorNotification: FunctionComponent<ErrorNotificationProps> = ({ type }) => {
  const { variant, title, children } = getNotificationStructure(type);

  return (
    <Notification variant={variant} title={title}>
      {children}
    </Notification>
  );
};

export default ErrorNotification;
