import { FunctionComponent } from "react";

import CheckOutTitleWithIllustration from "@components/CheckOutTitleWithIllustration";
import { CheckOutType } from "@components/CheckOutTitleWithIllustration/CheckOutTitleWithIllustration";

export enum DynamicActivityType {
  TRAINER = "TRAINER",
  PRACTICE_EXAM = "PRACTICE_EXAM",
}

type DynamicActivityProps = {
  dynamicActivity: DynamicActivityType;
  isSuccess: boolean;
};

const dynamicActivityResult = ({ dynamicActivity, isSuccess }: DynamicActivityProps) => {
  let checkOutType;

  switch (dynamicActivity) {
    case DynamicActivityType.TRAINER:
      checkOutType = isSuccess
        ? CheckOutType.TRAINER_CHECKOUT_SUCCESS
        : CheckOutType.TRAINER_CHECKOUT_FAILURE;
      break;
    case DynamicActivityType.PRACTICE_EXAM:
      checkOutType = isSuccess
        ? CheckOutType.PRACTICE_EXAM_STATUS_SUCCESS
        : CheckOutType.PRACTICE_EXAM_STATUS_FAILURE;
      break;
    default:
      throw new Error("This DynamicActivity doesn't exist");
  }
  return <CheckOutTitleWithIllustration checkOutType={checkOutType} />;
};

const CheckOutTitleWithIllustrationDynamicActivity: FunctionComponent<DynamicActivityProps> = ({
  dynamicActivity,
  isSuccess,
}) => {
  return dynamicActivityResult({ dynamicActivity, isSuccess });
};

export default CheckOutTitleWithIllustrationDynamicActivity;
