import styled, { css } from "styled-components";

import { pxToRem } from "@anwb/style-utilities";
import { Theme } from "@anwb/theme";

type DefaultThemeProps = {
  theme: Theme;
};
const errorBoundaryWrapperStyle = ({ theme }: DefaultThemeProps) => css`
  max-width: 80rem;
  margin: 0;
  padding: ${pxToRem(Number(theme.spacing["200"]))};
  padding-top: ${pxToRem(Number(theme.spacing["100"]))};

  @media (min-width: ${pxToRem(theme.viewportBreakpoint.sm)}) {
    margin: 0 auto;
    padding: ${pxToRem(Number(theme.spacing["400"]))};
  }
`;
export const ErrorBoundaryWrapper = styled.div`
  ${errorBoundaryWrapperStyle}
`;
